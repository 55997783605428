import React from 'react';
import { isValidArrayAndNotEmpty } from 'components/feature/Report/ReportSidebar/common/helpers';
import useLocalize from '../Localization/useLocalize.hook';

const ToolTipContentForDependentFilters = (name:string, parentFilterList:string[], childFilterList:string[]) => (
  <>
    <div style={{
      fontWeight: '600', fontFamily: 'SemiBold', padding: '12px 0px 0px 16px', color: 'var(--white)',
    }}
    >
      {useLocalize('reportBuilder.dependentFilterTitle')}
    </div>
    <hr style={{ borderBottom: '1px solid #ADBAC7' }} />
    {isValidArrayAndNotEmpty(parentFilterList) ? (
      <>
        <div style={{ color: '#ADBAC7', fontSize: '13px', padding: '0px 0px 8px 16px' }}>
          {useLocalize('reportBuilder.parentFilterTitle')}
          {' '}
          <span style={{ fontWeight: '600', fontFamily: 'SemiBold' }}>
            {name}
            :
          </span>
        </div>
        <div style={{
          padding: '0px 0px 0px 16px', fontWeight: '600', color: 'white', fontFamily: 'SemiBold',
        }}
        >
          {parentFilterList.join(', ')}
          {' '}
        </div>
      </>
    ) : null}
    {isValidArrayAndNotEmpty(childFilterList) ? (
      <>
        <div style={{ color: '#ADBAC7', fontSize: '13px', padding: '0px 0px 8px 16px' }}>
          {useLocalize('reportBuilder.childFilterTitle')}
          {' '}
          <span style={{ fontWeight: '600', fontFamily: 'SemiBold' }}>
            {name}
            :
          </span>
        </div>
        <div style={{
          padding: '0px 0px 8px 16px', fontWeight: '600', fontFamily: 'SemiBold', color: 'white',
        }}
        >
          {childFilterList.join(', ')}
          {' '}
        </div>
      </>
    ) : null}
    <hr style={{ borderBottom: '1px solid #ADBAC7' }} />
    <div style={{ padding: '0px 0px 12px 16px' }}>
      <span style={{ color: '#FD6966' }}>{useLocalize('jic.note')}</span>
      {' '}
      {useLocalize('reportBuilder.dependentFilterNote')}
    </div>
  </>
);

export default ToolTipContentForDependentFilters;
