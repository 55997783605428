import { IAccordianListItem, IAccordionList } from 'components/common/SideTray/side-tray.interface';
import { ActionIconsForAccordion } from 'core/constants/common';
import { DataSourceSelectionType, SideTrayType } from 'core/constants/report';
import { FilterOperatorTypes } from 'core/constants/report-expressions';
import { JoinItem } from 'core/models/report-builder/report-builder';
import { IExpression, newColumnExpr, newComparisonExpr } from 'core/models/report-expressions';
import { FieldEntities } from 'core/utils/report.util';
import {
  IDataSourceFieldType, IDataSourceMapping, IDataSourceResponse, IQueryInfo,
} from 'redux-v2/report-builder/report-builder-store.state';
import { SubQueryPanelType } from 'core/utils/report-builder.util';
import { JoinBlockValues } from './DataSourceEdit/data-source-component';
import { isValidArrayAndNotEmpty } from '../../Report/ReportSidebar/common/helpers';
import { JoinPrefix, ROOT_QUERY_KEY } from '../../Report/ReportSidebar/common/constants';

export const createTableList = (
  dataSources: IDataSourceResponse,
  selectedTables: Map<number, JoinBlockValues>,
  tableType: DataSourceSelectionType,
  joinBlockIndex: number,
  joinArray: JoinItem[],
  primaryDataSource: string,
  querySource: string,
  queryList: IQueryInfo[],
  selectedQuery: string,
): IAccordionList[] => {
  const geEntityActionIcon = () => {
    switch (tableType) {
      case DataSourceSelectionType.querySource:
        if (querySource) {
          return ActionIconsForAccordion.SWITCH;
        }
        return ActionIconsForAccordion.ADD;
      case DataSourceSelectionType.lhsTable:
        return (joinArray[joinBlockIndex]?.Primary) !== null ? ActionIconsForAccordion.SWITCH : ActionIconsForAccordion.ADD;
      case DataSourceSelectionType.rhsTable:
        return joinArray[joinBlockIndex]?.With !== null ? ActionIconsForAccordion.SWITCH : ActionIconsForAccordion.ADD;
      default: return ActionIconsForAccordion.ADD;
    }
  };
  const selectedQueryExtraData = isValidArrayAndNotEmpty(queryList) && queryList.find((query) => query.Id === selectedQuery);
  const queriesWithRootAsParent = isValidArrayAndNotEmpty(queryList) ? queryList.filter((query) => query.ParentQueryId === ROOT_QUERY_KEY).map((query) => query.Id) : [];
  const groupedData: Record<string, IAccordianListItem[]> = Object.keys(dataSources)
    .filter((tableKey) => {
      if (tableType === DataSourceSelectionType.querySource) {
        return tableKey !== querySource;
      }
      if (tableType === DataSourceSelectionType.lhsTable && joinBlockIndex === 0) {
        if (dataSources[tableKey].IsQuery && selectedQueryExtraData?.IsDraft) {
          const linkedQueryIds = selectedQueryExtraData.LinkedQueryIds;
          return linkedQueryIds?.includes(tableKey);
        }
        return !selectedTables.get(joinBlockIndex)?.selected?.includes(tableKey);
      }
      if (tableType === DataSourceSelectionType.lhsTable && joinBlockIndex > 0) {
        const part: any = joinArray[joinBlockIndex - 1]?.On?.Part;
        const primaryTable = joinArray[joinBlockIndex - 1]?.Primary || part?.LHS?.Part?.TableNameOverride?.replace(JoinPrefix, '') || primaryDataSource;
        const withTable = joinArray[joinBlockIndex - 1]?.With;
        const selectedTablesForBlock = selectedTables.get(joinBlockIndex)?.selected || [];
        return (tableKey === withTable || tableKey === primaryTable) && !selectedTablesForBlock.includes(tableKey);
      }
      const allSelectedTables = Array.from(selectedTables.values()).flatMap((joinBlockItem) => joinBlockItem.selected);
      if (dataSources[tableKey].IsQuery && !selectedQueryExtraData?.IsDraft) {
        const linkedQueryIds = queryList.find((query) => query.Id === tableKey)?.LinkedQueryIds;
        if (selectedQuery === ROOT_QUERY_KEY) {
          return queriesWithRootAsParent.includes(tableKey) && !allSelectedTables.includes(tableKey);
        }
        return linkedQueryIds?.includes(tableKey);
      }
      if (dataSources[tableKey]?.IsQuery && tableType === DataSourceSelectionType.rhsTable) {
        return false;
      }
      return !allSelectedTables.includes(tableKey);
    })
    .reduce((acc: Record<string, IAccordianListItem[]>, tableKey) => {
      const tableData = dataSources[tableKey];
      const entity = tableData.Entity;
      const newItem: IAccordianListItem = {
        name: tableData.DisplayName,
        uniqueKey: tableKey,
        toolTipContent: tableData.DisplayName,
        isMasked: false,
        originalIndex: 0,
        isItemDisable: false,
        iconItemToolTipText: tableData.DisplayName,
        renderIcon: geEntityActionIcon(),
        data: tableData,
      };
      if (!acc[entity]) {
        acc[entity] = [];
      }
      acc[entity].push(newItem);
      return acc;
    }, {});
  const accordions = Object.keys(groupedData).map((entityKey) => ({
    headerName: entityKey, // Use Entity as header
    headerIcon: FieldEntities[entityKey],
    accordValues: groupedData[entityKey],
    emptyStateText: `No ${entityKey} available`,
    allItemsAddedMessage: `All ${entityKey} selected`,
    groupId: entityKey,
    onAccordChange: () => {},
    openAccordSectionOnInitialLoad: false,
    isCustomExp: false,
  }));
  return accordions;
};

export const createFieldsList = (dataSources: IDataSourceResponse, entity: string, selectedFieldsMap: Map<number, JoinBlockValues>, joinBlockIndex: number): IAccordionList[] => Object.keys(dataSources)
  .filter((tableKey) => tableKey === entity)
  .map((tableKey) => {
    const tableData = dataSources[tableKey];
    const accordValues: IAccordianListItem[] = tableData.AdditionalFields
      .filter((field:IDataSourceFieldType) => {
        const selectedFields = selectedFieldsMap.get(joinBlockIndex)?.selected || [];
        if (!isValidArrayAndNotEmpty(selectedFields)) return true;
        return !selectedFields.includes(field.SchemaName);
      })
      .map((field:IDataSourceFieldType, index:number) => ({
        name: field.DisplayName,
        uniqueKey: `${tableKey}-${field.SchemaName}`,
        toolTipContent: field.DisplayName,
        isMasked: false,
        originalIndex: index,
        isItemDisable: false,
        iconItemToolTipText: field.DisplayName,
        renderIcon: selectedFieldsMap.get(joinBlockIndex)?.selected ? ActionIconsForAccordion.SWITCH : ActionIconsForAccordion.ADD,
        data: field,
        openAccordSectionOnInitialLoad: false,
      }));
    return {
      headerName: tableData.DisplayName,
      accordValues,
      emptyStateText: 'No additional fields available',
      allItemsAddedMessage: 'All additional fields added',
      groupId: tableData.Entity,
      headerIcon: FieldEntities[tableData.Entity]?.icon,
      onAccordChange: () => {},
      openAccordSectionOnInitialLoad: false,
      isCustomExp: false,
    };
  });

export const getSidetrayAccordianList = (
  sideTrayContent: DataSourceSelectionType,
  dataSources: IDataSourceResponse,
  joinsArray: JoinItem[],
  selectedRHSTables: Map<number, JoinBlockValues>,
  defaultNamespace: string,
  selectedFields: Map<number, JoinBlockValues>,
  joinBlockIndex: number,
  selectedLHSTables: Map<number, JoinBlockValues>,
  querySource: string,
  queryList: IQueryInfo[],
  selectedQuery: string,
) => {
  let accordionList: IAccordionList[] = [];
  const dataSourcesKeys = Object.keys(dataSources);
  let preDefinedDataSources = {};
  dataSourcesKeys.forEach((dataSourceValue) => {
    if (!dataSources[dataSourceValue].IsQuery) {
      preDefinedDataSources = {
        ...preDefinedDataSources, [dataSourceValue]: dataSources[dataSourceValue],
      };
    }
  });
  if (sideTrayContent === DataSourceSelectionType.querySource) {
    accordionList = createTableList(preDefinedDataSources, new Map(), DataSourceSelectionType.querySource, joinBlockIndex, joinsArray, defaultNamespace, querySource, queryList, selectedQuery);
    return accordionList;
  }
  if (isValidArrayAndNotEmpty(joinsArray)) {
    const joinItem = joinsArray[joinBlockIndex];
    const expression: IExpression = joinItem?.On;
    const part: any = expression?.Part;
    switch (sideTrayContent) {
      case DataSourceSelectionType.lhsTable: {
        accordionList = createTableList(dataSources, selectedLHSTables, DataSourceSelectionType.lhsTable, joinBlockIndex, joinsArray, defaultNamespace, querySource, queryList, selectedQuery);
        break;
      }
      case DataSourceSelectionType.rhsTable: {
        accordionList = createTableList(dataSources, selectedRHSTables, DataSourceSelectionType.rhsTable, joinBlockIndex, joinsArray, defaultNamespace, querySource, queryList, selectedQuery);
        break;
      }
      case DataSourceSelectionType.lhsField: {
        accordionList = createFieldsList(dataSources, joinItem?.Primary || part?.LHS?.Part?.TableNameOverride?.replace(JoinPrefix, '') || defaultNamespace, selectedFields, joinBlockIndex);
        break;
      }
      case DataSourceSelectionType.rhsField: {
        accordionList = createFieldsList(dataSources, joinItem?.With, selectedFields, joinBlockIndex);
        break;
      }
      default: break;
    }
  }
  return accordionList;
};

export const getDefaultJoinCriteria = (primaryDataSource:string, withDataSource: string, dataSources: IDataSourceResponse, primaryNamespace?: string): IExpression => {
  if (!primaryDataSource || !withDataSource) return null;
  const primaryDataSourceDefaultField = dataSources[primaryDataSource].DefaultField;
  const withDataSourceDefaultField = dataSources[withDataSource].DefaultField;
  const lhs = newColumnExpr(primaryDataSourceDefaultField.DisplayName, primaryDataSourceDefaultField.SchemaName, primaryDataSourceDefaultField.DataType, primaryDataSource === primaryNamespace ? primaryDataSource : `${JoinPrefix}${primaryDataSource}`);
  const rhs = newColumnExpr(withDataSourceDefaultField.DisplayName, withDataSourceDefaultField.SchemaName, withDataSourceDefaultField.DataType, withDataSource === primaryNamespace ? withDataSource : `${JoinPrefix}${withDataSource}`);
  const compExpr = newComparisonExpr(lhs, rhs, FilterOperatorTypes.EQ);
  return compExpr;
};

export const getDefaultBreadcrumbData = () => [
  {
    label: 'Main Report', isActive: false, queryId: ROOT_QUERY_KEY, type: SubQueryPanelType.query,
  },
];

/**
 * Generates a display string of data source names based on the provided mapping and data sources
 * Used to show the connected/joined data sources in the UI
 *
 * @param dataSourceMapping - Object containing mapping information for queries including joins and primary namespace
 * @param queryId - Unique identifier for the query whose data source names need to be retrieved
 * @param dataSources - Object containing information about all available data sources including their display names
 * @returns A string representing either a single data source name or concatenated names of joined data sources
 *
 */
export const getDataSourceNames = (
  dataSourceMapping: IDataSourceMapping,
  queryId: string,
  dataSources: IDataSourceResponse,
): string => {
  const joinsList = dataSourceMapping[queryId]?.Joins;
  if (!isValidArrayAndNotEmpty(joinsList)) {
    if (dataSourceMapping[queryId]?.PrimaryNamespace) {
      return dataSources[dataSourceMapping[queryId]?.PrimaryNamespace]?.DisplayName;
    }
    return '';
  }
  const joinBlock = joinsList[dataSourceMapping[queryId].JoinBlockIndexBeingEdited] || {} as JoinItem;
  if (!(joinBlock?.Primary || joinBlock?.With)) {
    return dataSources[dataSourceMapping[queryId]?.PrimaryNamespace]?.DisplayName;
  }
  const primaryTable = dataSources[joinBlock?.Primary]?.DisplayName || dataSources[dataSourceMapping[queryId].PrimaryNamespace]?.DisplayName;
  const withTable = dataSources[joinBlock.With]?.DisplayName || '';

  return withTable ? `${primaryTable} + ${withTable}` : primaryTable;
};

export const getQueryName = (queryId: string, queryList: IQueryInfo[]) => queryList.find((query) => query.Id === queryId)?.Name || '';
export const getPanelSource = (type: DataSourceSelectionType) => {
  switch (type) {
    case DataSourceSelectionType.lhsTable:
    case DataSourceSelectionType.rhsTable: return SideTrayType.DataSourceEntity;
    case DataSourceSelectionType.lhsField:
    case DataSourceSelectionType.rhsField: return SideTrayType.DataSourceField;
    default: return SideTrayType.None;
  }
};
