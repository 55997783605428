import React from 'react';

export interface IProps {
  width: number;
  height: number;
  fill?: string;
}

const QueryIcon = ({
  width, height, fill,
}: IProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill={fill}>
    <path d="M6.66699 15L1.66699 10L6.66699 5L7.85449 6.1875L4.02116 10.0208L7.83366 13.8333L6.66699 15ZM13.3337 15L12.1462 13.8125L15.9795 9.97917L12.167 6.16667L13.3337 5L18.3337 10L13.3337 15Z" fill={fill} />
  </svg>
);

QueryIcon.defaultProps = {
  fill: 'var(--primaryColor)',
};

export default QueryIcon;
