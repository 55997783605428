import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const MavisIcon = ({ width, height, fill }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20.252 24.11"
    fill={fill}
  >
    <g id="Group_165627" data-name="Group 165627" transform="translate(-40.961)">
      <path
        id="Path_84064"
        data-name="Path_84064"
        d="M40.96,223.171v4.489c1.534,1.519,5.035,2.361,10.126,2.361s8.592-.842,10.126-2.361v-4.489c-1.667,1.135-4.695,2.028-10.126,2.028S42.627,224.306,40.96,223.171Z"
        transform="translate(0 -212.662)"
      />
      <path
        id="Path_84065"
        data-name="Path_84065"
        d="M40.96,16.3v4.439c0,2.216,5.094,3.375,10.126,3.375s10.126-1.159,10.126-3.375V16.3c-1.667,1.135-4.695,2.028-10.126,2.028S42.627,17.431,40.96,16.3ZM51.086,0C46.054,0,40.96,1.159,40.96,3.375v.05C42.494,4.944,46,5.786,51.086,5.786s8.592-.842,10.126-2.361v-.05C61.212,1.159,56.118,0,51.086,0Z"
      />
      <path
        id="Path_84066"
        data-name="Path_84066"
        d="M40.96,100.291v4.489c1.534,1.519,5.035,2.361,10.126,2.361s8.592-.842,10.126-2.361v-4.489c-1.667,1.135-4.695,2.028-10.126,2.028S42.627,101.426,40.96,100.291Z"
        transform="translate(0 -95.568)"
      />
    </g>
  </svg>
);

MavisIcon.defaultProps = {
  fill: 'var(--searchBoxTextColor)',
};

export default MavisIcon;
