import { APIRequestStatus } from 'core/constants/redux';
import { IPanelDataResponse } from 'core/models/dashboard';
import { IDimension } from 'core/models/report-redux';
import {
  IBuilder,
  IPaging,
  IReportConfig, ISorting, IRawTemplate, ITemplateSetting, JoinItem, ReportBuilderStatus, SortingOrderTypes,
} from 'core/models/report-builder/report-builder';
import { FilterModel, ObjModel, ReportResponseModel } from 'core/models';
import {
  IField, IColumn, IFilterConfig, IColumnFormatter,
} from 'core/models/report-response';
import {
  ColumnDataTypes, ColumnModes, DataSourceSelectionType, DimensionMode, FieldEntitiesType, draggableItemType,
} from 'core/constants/report';
import { FilterType, UserFilterPayloadType } from 'core/constants/filter';
import { IExpression } from 'core/models/report-expressions';
import { IReportDetails } from 'redux-v2/global-store/global-store.state';
import { IFilterResponse } from 'core/models/filter';
import { IVariables } from 'core/models/store';
import { IBreadcrumbItem } from 'components/common/Breadcrumbs/breadcrumbs.component';
import { SubQueryPanelType } from 'core/utils/report-builder.util';
import { PageContext } from 'core/constants/common';

export interface IReportBuilderState {
  requestProcessing: IRequestProcessing;
  reportBuilderInfo: IReportBuilderInfo;
  updatedReportBuilderInfo: IUpdatedReportBuilderInfo;
  dataSourceInfo: IDataSourceInfo;
  assortedInfo: IAssortedInfo;
  previewData: IPreviewData;
  filterBuilder: IFilterBuilder;
  previewBackUpInfo: IPreviewBackUpInfo;
  reportEditInfo: IReportEditInfo;
  reportQueryBuilder: IQueryBuilder;
}

export interface ICustomExpression{
  displayName: string;
  jsonExpression?: IExpression;
  uniqueId?: string | number;
  Mode: ColumnModes;
  Formatter:IColumnFormatter;
  DataType:ColumnDataTypes;
  dimensionType?: DimensionMode;
  ExpressionType: ExpressionContext;
}

export enum ExpressionContext {
  Measure = 'Measure',
  Dimension = 'Dimension'
}

export interface IPreviewData {
  data: IPanelDataResponse;
  mappedMetaFields: object;
}

export interface IQueryPreviewData{
    data: IPanelDataResponse;
    mappedMetaFields: object;
    previewQueryId: string;
    previewBackupInfo: {
      previewInfo: IQueryBuilderUpdatedInfo
    }
}

// to hold the saved preview data for comparision
export interface IPreviewBackUpInfo {
  updatedReportBuilderInfo: IUpdatedReportBuilderInfo;
  filterBuilder: IFilterBuilder;
}

export enum ReportBuilderSaveStatus {
  DRAFT = 1,
  CREATED,
  EDITED,
}

export interface IUpdateRequestProcessingPayload {
  type: ReportBuilderActions;
  status: APIRequestStatus;
}

export interface IRequestProcessing {
  [ReportBuilderActions.GET_TEMPLATE_LIST]: APIRequestStatus;
  [ReportBuilderActions.INITIAL_SAVE_TEMPLATE]: APIRequestStatus;
  [ReportBuilderActions.SAVE_REPORT_CONFIG]: APIRequestStatus;
  [ReportBuilderActions.GET_ADDITIONAL_FILTERS]: APIRequestStatus;
  [ReportBuilderActions.GET_ADDITIONAL_DIMENSIONS]: APIRequestStatus;
  [ReportBuilderActions.GET_ADDITIONAL_MEASURES]: APIRequestStatus;
  [ReportBuilderActions.GET_PREVIEW_DATA]: APIRequestStatus;
  [ReportBuilderActions.FILTER_DATA_LOAD_REQUEST]: ObjModel.ObjGeneric<APIRequestStatus>;
  [ReportBuilderActions.SUB_QUERY_FILTER_LOAD_DATA_REQUEST]: ObjModel.ObjGeneric<APIRequestStatus>;
  [ReportBuilderActions.GET_FIELDS]: APIRequestStatus;
  [ReportBuilderActions.GET_REPORT_RAW_CONFIG_REQUEST]: APIRequestStatus;
  [ReportBuilderActions.GET_REPORT_RENDER_CONFIG_REQUEST]: APIRequestStatus;
  [ReportBuilderActions.GET_MEASURE_DRILLDOWN_CONFIG]: APIRequestStatus;
  [ReportBuilderActions.GET_DRILLDOWN_CONFIG]: APIRequestStatus;
  [ReportBuilderActions.SAVE_DRILLDOWN_CONFIG]: APIRequestStatus;
  [ReportBuilderActions.GET_DEFAULT_DRILLDOWN_COLUMN]: APIRequestStatus;
  [ReportBuilderActions.GET_ADDITIONAL_DRILLDOWN_COLUMN]: APIRequestStatus;
  [ReportBuilderActions.GET_RAW_TEMPLATE_DETAILS]: APIRequestStatus;
  [ReportBuilderActions.GET_ADDITIONAL_USER_FIELD_FILTERS]: APIRequestStatus;
  [ReportBuilderActions.GET_ADDITIONAL_USER_DIMENSIONS]: APIRequestStatus
  [ReportBuilderActions.GET_DATA_SOURCES_REQUEST]:APIRequestStatus;
  [ReportBuilderActions.SAVE_DATA_SOURCE_CHANGES_REQUEST]:APIRequestStatus
  [ReportBuilderActions.GET_ADDITIONAL_DIMENSIONS_FOR_NAMESPACES]: APIRequestStatus;
  [ReportBuilderActions.GET_ADDITIONAL_FILTERS_FOR_NAMESPACES]: APIRequestStatus;
  [ReportBuilderActions.GET_ADDITIONAL_MEASURES_FOR_NAMESPACES]: APIRequestStatus;
  [ReportBuilderActions.GET_ADDITIONAL_USER_FIELDS_FOR_NAMESPACES]: APIRequestStatus;
  [ReportBuilderActions.GET_ADDITIONAL_USER_FILTERS_FOR_NAMESPACES]: APIRequestStatus;
  [ReportBuilderActions.GET_ADDITIONAL_FIELDS_FOR_NAMESPACES]: APIRequestStatus;
  [ReportBuilderActions.SAVE_NESTED_JOINS_CONFIG]: APIRequestStatus;
  [ReportBuilderActions.GET_SUB_QUERY_PREVIEW_DATA]: APIRequestStatus;
}

export interface IReportBuilderInfo {
  templateList: Array<IRawTemplate>;
  additionalFilters: Array<ReportResponseModel.IFilterConfig>;
  additionalDimensions: Array<ReportResponseModel.IColumn>;
  additionalMeasures: Array<ReportResponseModel.IColumn>;
  additionalUserFieldFilters: Array<ReportResponseModel.IFilterConfig>;
  additionalUserFields: Array<ReportResponseModel.IColumn>;
  fields:Array<IField>;
}

export interface IUpdatedReportBuilderInfo {
  selectedTemplate: IRawTemplate;
  reportBuilderSavePayload: IReportConfig;
  appliedDimensions: IDimension[];
  appliedMeasures: IColumn[];
  sortState: ISorting[];
  calculatedColumns: Set<string>;
  expressionStore: ObjModel.ObjGeneric<ICustomExpression>;
  drilldownConfigInfo: IdrilldownConfigInfo;
}

export interface IdrilldownConfigInfo{
  drilldownConfigMapping: DrilldownConfigMapping,
  drilldownColumnConfig: DrilldownColumnConfig,
  enableDrilldown: boolean,
  drilldownPagination: IPaging,
}
export interface IAssortedInfo {
  isFormDisabled: boolean;
  selectedTabInfo: string;
  reportId: string;
  settings: ITemplateSetting;
  reportBuilderSaveStatus: ReportBuilderSaveStatus;
  isNestedQueryFormOpen: boolean;
  isDataSourceEditMode: boolean;
  activeSubQueryPanel: SubQueryPanelType;
  isDataSourceSidetrayExpanded: boolean;
  isDrilldownMeasuresPanelExpanded: boolean,
  isDrilldownFieldsPanelExpanded: boolean,
  isReportDataSourceSaved: boolean
}

export interface IFilterBuilder {
  filterConfig: Array<IFilterConfig>;
  appliedFilters: ObjModel.ObjGeneric<FilterModel.IFilterResponse>;
  isUsersModified: boolean;
  filterMetaInfo: IFilterMetaInfo[];
  UserDropdownSelectType?: UserFilterPayloadType
}

export interface IFilterMetaInfo {
  IsPartitionColumn: boolean;
  Entity: FieldEntitiesType;
  ID: string;
  Metadata?: ObjModel.Obj;
  Alias: string;
  Label: string;
  LinkedDimensionAlias: string;
  LinkedTo: string;
  Operator: string;
  Type?: FilterType;
}

export interface IFilterMetaInfoStore {
  Entity: FieldEntitiesType;
  IsPartitionColumn: boolean;
  Metadata?: ObjModel.Obj;
  LinkedTo: string;
}

export interface IEditProfilePayload {
  Profiles:Array<string>;
  Reports: Array<IReportDetails>;
}

export interface IReportEditInfo {
  rawReportConfig: IReportConfig;
  renderReportConfig: ReportResponseModel.IReportConfig;
  combineConfigLoaded: boolean;
  isEditing: boolean;
  temporaryReportId: string;
}

export enum ReportBuilderActions {
  GET_TEMPLATE_LIST = '[RB] GET_TEMPLATE_LIST',
  UPDATED_SELECTED_TEMPLATE = '[RB] UPDATED_SELECTED_TEMPLATE',
  INITIAL_SAVE_TEMPLATE = '[RB] INITIAL_SAVE_TEMPLATE',
  GET_ADDITIONAL_FILTERS = '[RB] GET_ADDITIONAL_FILTERS',
  GET_ADDITIONAL_USER_FIELD_FILTERS = '[RB] GET_ADDITIONAL_USER_FIELD_FILTERS',
  GET_ADDITIONAL_MEASURES = '[RB] GET_ADDITIONAL_MEASURES',
  GET_ADDITIONAL_DIMENSIONS = '[RB] GET_ADDITIONAL_DIMENSIONS',
  FILTER_DATA_LOAD_REQUEST = '[RB] FILTER_DATA_LOAD_REQUEST',
  UPDATE_REPORT_TEMPLATE_DETAIL = '[RB] UPDATE_REPORT_TEMPLATE_DETAIL',
  SEARCH_FILTER_DATA_LOAD_REQUEST = '[RB] SEARCH_FILTER_DATA_LOAD_REQUEST',
  LOAD_MORE_FILTER_DATA_LOAD_REQUEST = '[RB] LOAD_MORE_FILTER_DATA_LOAD_REQUEST',
  ADD_DYNAMIC_FILTER = '[RB] ADD_DYNAMIC_FILTER',
  SWITCH_USER_FIELD_AND_FILTER = '[RB] SWITCH_USER_FIELD_AND_FILTER',
  DELETE_DYNAMIC_FILTER = '[RB] DELETE_DYNAMIC_FILTER',
  FILTER_VALUE_CHANGE = '[RB] FILTER_VALUE_CHANGE',
  RESET_REPORT_TEMPLATE = '[RB] RESET_REPORT_TEMPLATE',
  SAVE_REPORT_CONFIG = '[RB] SAVE_REPORT_CONFIG',
  UPDATE_SELECTED_TAB = '[RB] UPDATE_SELECTED_TAB',
  UPDATE_VISUALIZATION = '[RB] UPDATE_VISUALIZATION',
  GET_PREVIEW_DATA = '[RB] GET_PREVIEW_DATA',
  SET_DIMENSIONS_GROUPING = '[RB] SET_DIMENSIONS_GROUPING',
  SET_DIMENSIONS_BINNING = '[RB] SET_DIMENSIONS_BINNING',
  UPDATE_PAGINATION = '[RB] UPDATE_PAGINATION',
  ADD_MEASURE = '[RB] ADD_MEASURE',
  REM_MEASURE = '[RB] REM_MEASURE',
  MOV_MEASURE = '[RB] MOV_MEASURE',
  SET_MEASURE = '[RB] SET_MEASURE',
  UPDATE_FILTER_FORM = '[RB] UPDATE_FILTER_FORM',
  UPDATE_SAVE_REPORT_BUILDER_STATUS = '[RB] UPDATE_SAVE_REPORT_BUILDER_STATUS',
  SET_REQUEST_PROCESSING_STATE = '[RB] SET_REQUEST_PROCESSING_STATE',
  SORT_COLUMN = '[RB] SORT_COLUMN',
  ADD_OR_UPDATE_CUSTOM_EXPRESSION='[RB] ADD_OR_UPDATE_CUSTOM_EXPRESSION',
  DELETE_CUSTOM_EXPRESSION='[RB] DELETE_CUSTOM_EXPRESSION',
  GET_FIELDS='[RB] GET_FIELDS',
  GET_FIELDS_FAILURE='[RB] GET_FIELDS_FAILURE',
  GET_FIELDS_SUCCESS='[RB] GET_FIELDS_SUCCESS',
  GET_REPORT_RAW_CONFIG_REQUEST = '[RB] GET_REPORT_RAW_CONFIG_REQUEST',
  GET_REPORT_RENDER_CONFIG_REQUEST = '[RB] GET_REPORT_RENDER_CONFIG_REQUEST',
  COMBINE_RAW_AND_RENDER_CONFIG = '[RB] COMBINE_RAW_AND_RENDER_CONFIG',
  SET_REPORT_EDIT_ID = '[RB] SET_REPORT_EDIT_ID',
  SET_TEMPORARY_REPORT_ID = '[RB] SET_TEMPORARY_REPORT_ID',
  SET_INITIAL_SUCCESS_STATE = '[RB] SET_INITIAL_SUCCESS_STATE',
  SET_INITIAL_FAILURE_STATE = '[RB] SET_INITIAL_FAILURE_STATE',
  SET_PREVIEW_DATA_SUCCESS_STATE = '[RB] SET_PREVIEW_DATA_SUCCESS_STATE',
  SET_PREVIEW_DATA_FAILURE_STATE = '[RB] SET_PREVIEW_DATA_FAILURE_STATE',
  GET_MEASURE_DRILLDOWN_CONFIG = '[RB] GET_MEASURE_DRILLDOWN_CONFIG',
  ADD_DRILLDOWN_CONFIGURATION = '[RB] ADD_DRILLDOWN_CONFIGURATION',
  ADD_DRILLDOWN_MEASURE = '[RB] ADD_DRILLDOWN_MEASURE',
  SET_SELECTED_MEASURE = '[RB] SET_SELECTED_MEASURE',
  REMOVE_DRILLDOWN_ACCORD = '[RB] REMOVE_DRILLDOWN_ACCORD',
  REMOVE_DRILLDOWN_MEASURE = '[RB] REMOVE_DRILLDOWN_MEASURE',
  INIT_DRILLDOWN_MAPPING = '[RB] INIT_DRILLDOWN_MAPPING',
  SORT_DRILLDOWN_COLUMN = '[RB] SORT_DRILLDOWN_COLUMN',
  ADD_REMOVE_DRILLDOWN_FIELD = '[RB] ADD_REMOVE_DRILLDOWN_FIELD',
  UPDATE_DRILLDOWN_COLUMN = '[RB] UPDATE_DRILLDOWN_COLUMN',
  MOVE_DRILLDOWN_COLUMN = '[RB] MOVE_DRILLDOWN_COLUMN',
  TOGGLE_DRILLDOWN = '[RB] TOGGLE_DRILLDOWN',
  UPDATE_DRILLDOWN_PAGINATION = '[RB] UPDATE_DRILLDOWN_PAGINATION',
  GET_DRILLDOWN_CONFIG = '[RB] GET_DRILLDOWN_CONFIG',
  SAVE_DRILLDOWN_CONFIG = '[RB] SAVE_DRILLDOWN_CONFIG',
  GET_DEFAULT_DRILLDOWN_COLUMN = '[RB] GET_DEFAULT_DRILLDOWN_COLUMN',
  GET_ADDITIONAL_DRILLDOWN_COLUMN = '[RB] GET_ADDITIONAL_DRILLDOWN_COLUMN',
  GET_RAW_TEMPLATE_DETAILS = '[RB] GET_RAW_TEMPLATE_DETAILS',
  GET_RAW_TEMPLATE_DETAILS_SUCCESS = '[RB] GET_RAW_TEMPLATE_DETAILS_SUCCESS',
  GET_RAW_TEMPLATE_DETAILS_FAILURE = '[RB] GET_RAW_TEMPLATE_DETAILS_FAILURE',
  RESET_GET_RAW_TEMPLATE_DETAILS='[RB] RESET_GET_RAW_TEMPLATE_DETAILS',
  SAVE_DRILLDOWNCOLS_WITH_REPORT_CONFIG='SAVE_DRILLDOWNCOLS_WITH_REPORT_CONFIG',
  GET_ADDITIONAL_USER_DIMENSIONS = '[RB] GET_ADDITIONAL_USER_DIMENSIONS',
  GET_DATA_SOURCES_REQUEST='[RB] GET_DATA_SOURCES_REQUEST',
  UPDATE_DATA_SOURCE_CHANGES = '[RB] UPDATE_DATA_SOURCE_CHANGES',
  SAVE_DATA_SOURCE_CHANGES_REQUEST = '[RB] SAVE_DATA_SOURCE_CHANGES_REQUEST',
  SAVE_DATA_SOURCE_CHANGES_SUCCESS = '[RB] SAVE_DATA_SOURCE_CHANGES_SUCCESS',
  SAVE_DATA_SOURCE_CHANGES_FAILURE = '[RB] SAVE_DATA_SOURCE_CHANGES_FAILURE',
  TOGGLE_DATA_SOURCE_EDIT_MODE = '[RB] TOGGLE_DATA_SOURCE_EDIT_MODE',
  RESET_REPORT_CONFIG_FOR_DATA_SOURCE='[RB] RESET_REPORT_CONFIG_FOR_DATA_SOURCE ',
  UPDATE_REPORT_METADATA_POST_JOINS_SAVE = '[RB] UPDATE_REPORT_METADATA_POST_JOINS_SAVE',
  UPDATE_DATA_SOURCE_SIDETRAY_STATE = '[RB] UPDATE_DATA_SOURCE_SIDETRAY_STATE',
  GET_ADDITIONAL_DIMENSIONS_FOR_NAMESPACES = '[RB] GET_ADDITIONAL_DIMENSIONS_FOR_NAMESPACES',
  GET_ADDITIONAL_FILTERS_FOR_NAMESPACES = '[RB] GET_ADDITIONAL_FILTERS_FOR_NAMESPACES',
  GET_ADDITIONAL_MEASURES_FOR_NAMESPACES = '[RB] GET_ADDITIONAL_MEASURES_FOR_NAMESPACES',
  GET_ADDITIONAL_FIELDS_FOR_NAMESPACES = '[RB] GET_ADDITIONAL_FIELDS_FOR_NAMESPACES',
  GET_ADDITIONAL_USER_FIELDS_FOR_NAMESPACES = '[RB] GET_ADDITIONAL_USER_FIELDS_FOR_NAMESPACES',
  GET_ADDITIONAL_USER_FILTERS_FOR_NAMESPACES = '[RB] GET_ADDITIONAL_USER_FILTERS_FOR_NAMESPACES',
  SET_QUERY_BUILDER_DIMENSIONS_GROUPING = '[RB] SET_QUERY_BUILDER_DIMENSIONS_GROUPING',
  SET_QUERY_BUILDER_DIMENSIONS_BINNING = '[RB] SET_QUERY_BUILDER_DIMENSIONS_BINNING',
  ADD_QUERY_BUILDER_MEASURE = '[RB] ADD_QUERY_BUILDER_MEASURE',
  REM_QUERY_BUILDER_MEASURE = '[RB] REM_QUERY_BUILDER_MEASURE',
  MOV_QUERY_BUILDER_MEASURE = '[RB] MOV_QUERY_BUILDER_MEASURE',
  SET_QUERY_BUILDER_MEASURE = '[RB] SET_QUERY_BUILDER_MEASURE',
  UPDATE_QUERY_BUILDER_FILTER_FORM = '[RB] UPDATE_QUERY_BUILDER_FILTER_FORM',
  ADD_OR_UPDATE_QUERY_BUILDER_CUSTOM_EXPRESSION='[RB] ADD_OR_UPDATE_QUERY_BUILDER_CUSTOM_EXPRESSION',
  DELETE_QUERY_BUILDER_CUSTOM_EXPRESSION='[RB] DELETE_QUERY_BUILDER_CUSTOM_EXPRESSION',
  ADD_QUERY_BUILDER_DYNAMIC_FILTER = '[RB] ADD_QUERY_BUILDER_DYNAMIC_FILTER',
  SWITCH_QUERY_BUILDER_USER_FIELD_AND_FILTER = '[RB] SWITCH_QUERY_BUILDER_USER_FIELD_AND_FILTER',
  DELETE_QUERY_BUILDER_DYNAMIC_FILTER = '[RB] DELETE_QUERY_BUILDER_DYNAMIC_FILTER',
  QUERY_BUILDER_FILTER_VALUE_CHANGE = '[RB] QUERY_BUILDER_FILTER_VALUE_CHANGE',
  CREATE_NEW_QUERY = '[RB] CREATE_NEW_QUERY',
  TOGGLE_NESTED_QUERY_BUILDER_MODE = '[RB] TOGGLE_NESTED_QUERY_BUILDER_MODE',
  ADD_GROUPING_FOR_SUB_QUERY = '[RB] ADD_GROUPING_FOR_SUB_QUERY',
  SET_DIMENSIONS_BINNING_FOR_QUERY = '[RB] SET_DIMENSIONS_BINNING_FOR_QUERY',
  ADD_MEASURE_FOR_SUB_QUERY = '[RB] ADD_MEASURE_FOR_SUB_QUERY',
  REMOVE_MEASURE_FOR_SUB_QUERY = '[RB] REMOVE_MEASURE_FOR_SUB_QUERY',
  MOVE_MEASURE_FOR_SUB_QUERY = '[RB] MOVE_MEASURE_FOR_SUB_QUERY',
  SET_MEASURE_FOR_SUB_QUERY = '[RB] SET_MEASURE_FOR_SUB_QUERY',
  ADD_DYNAMIC_FILTER_IN_SUB_QUERY = '[RB] ADD_DYNAMIC_FILTER_IN_SUB_QUERY',
  DELETE_DYNAMIC_FILTER_IN_SUB_QUERY = '[RB] DELETE_DYNAMIC_FILTER_IN_SUB_QUERY',
  SUB_QUERY_FILTER_VALUE_CHANGE = '[RB] SUB_QUERY_FILTER_VALUE_CHANGE',
  SUB_QUERY_SEARCH_FILTER_DATA_LOAD_REQUEST = '[RB] SUB_QUERY_SEARCH_FILTER_DATA_LOAD_REQUEST',
  SUB_QUERY_LOAD_MORE_FILTER_DATA_LOAD_REQUEST = '[RB] SUB_QUERY_LOAD_MORE_FILTER_DATA_LOAD_REQUEST',
  UPDATE_SUB_QUERY_FILTER_FORM = '[RB] UPDATE_SUB_QUERY_FILTER_FORM',
  SUB_QUERY_FILTER_LOAD_DATA_REQUEST = '[RB] SUB_QUERY_FILTER_LOAD_DATA_REQUEST',
  SUB_QUERY_SORT_COLUMN= '[RB] SUB_QUERY_SORT_COLUMN',
  DELETE_SUB_QUERY = '[RB] DELETE_SUB_QUERY',
  UPDATE_SUB_QUERY_DATA_SOURCE_JOINS_ARRAY = '[RB] UPDATE_SUB_QUERY_DATA_SOURCE_JOINS_ARRAY',
  UPDATE_SUB_QUERY_DATA_SOURCE_NAMESPACE = '[RB] UPDATE_SUB_QUERY_DATA_SOURCE_NAMESPACE',
  SET_QUERY_NAMESPACE = '[RB] SET_QUERY_NAMESPACE',
  UPDATE_SELECTED_QUERY = '[RB] UPDATE_SELECTED_QUERY',
  DELETE_SELECTED_QUERY = '[RB] DELETE_SELECTED_QUERY',
  RESET_SELECTED_QUERY = '[RB] RESET_SELECTED_QUERY',
  DELETE_UNSAVED_DATA_SOURCE = '[RB] DELETE_UNSAVED_DATA_SOURCE',
  SUBQUERY_ADD_OR_UPDATE_CUSTOM_EXPRESSION= '[RB] SUBQUERY_ADD_OR_UPDATE_CUSTOM_EXPRESSION',
  SAVE_NESTED_JOINS_CONFIG = '[RB] SAVE_NESTED_JOINS_CONFIG',
  SAVE_SUBQUERY = '[RB] SAVE_SUBQUERY',
  UPDATE_JOIN_BLOCK_INDEX = '[RB] UPDATE_JOIN_BLOCK_INDEX',
  GET_SUB_QUERY_PREVIEW_DATA = '[RB] GET_SUB_QUERY_PREVIEW_DATA',
  SET_SUB_QUERY_PREVIEW_DATA_SUCCESS = '[RB] SET_SUB_QUERY_PREVIEW_DATA_SUCCESS',
  SET_SUB_QUERY_PREVIEW_DATA_FAILURE = '[RB] SET_SUB_QUERY_PREVIEW_DATA_FAILURE',
  SET_QUERY_EDIT_MODE = '[RB] SET_QUERY_EDIT_MODE',
  SET_DATA_SOURCE_EDIT_MODE = '[RB] SET_DATA_SOURCE_EDIT_MODE',
  SET_ACTIVE_SUBQUERY_PANEL = '[RB] SET_ACTIVE_SUBQUERY_PANEL',
  UPDATE_DATA_SOURCE_BREADCRUMB = '[RB] UPDATE_DATA_SOURCE_BREADCRUMB',
  UPDATE_INDEX_OF_JOIN_BLOCK_BEING_EDITED_INDEX = '[RB] UPDATE_INDEX_OF_JOIN_BLOCK_BEING_EDITED_INDEX',
  UPDATE_DRILLDOWN_MEASURES_SIDETRAY_STATE = '[RB] UPDATE_DRILLDOWN_MEASURES_SIDETRAY_STATE',
  UPDATE_DRILLDOWN_FIELDS_SIDETRAY_STATE = '[RB] UPDATE_DRILLDOWN_FIELDS_SIDETRAY_STATE',
  UPDATE_SUB_QUERY_PAGINATION_STATE = '[RB] UPDATE_SUB_QUERY_PAGINATION_STATE',
  RESET_QUERY_BUILDER_STATE = '[RB] RESET_QUERY_BUILDER_STATE',
  UPDATE_QUERY_BUILDER_STATE = '[RB] UPDATE_QUERY_BUILDER_STATE',
  UPDATE_PARENT_REPORT_DATA_SOURCE_SAVE_STATUS = '[RB] UPDATE_PARENT_REPORT_DATA_SOURCE_SAVE_STATUS',
}

export interface ISaveSuccessPayload {
  reportId: string;
  saveType: ReportBuilderStatus;
}

export interface IFilterDataLoadRequestPayload {
  filterId: string;
  variables: IVariables;
  filterValue?: any;
  isLazyLoad?: boolean;
}

export interface ISearchFilterPayloadRequest {
  filterId: string;
  searchKey: string;
}
export interface ILoadMoreFilterPayloadRequest {
  filterId: string;
}

export interface IFilterReducerPayload {
  filterId: string;
  isLazyLoad: boolean;
}

export interface IFilterDataLoadPayload {
  filterId: string;
  filterResponse: IFilterResponse;
}

export interface IAddDynamicFilterPayload {
  index: number;
}

export interface IDeleteDynamicFilterPayload {
  filterId: string;
}

export interface IFilterValueChangePayload {
  filterId: string;
  filterValue: string;
  variables: IVariables;
}

export interface IFilterFormValues {
  name: string;
  filterId: string;
}

export interface IAddMeasurePayload {
  index: number;
  status: ColumnModes;
}

export interface IRemoveMeasurePayload {
  index: number;
}

export interface IMoveMeasurePayload {
  sourceIndex: number;
  destinationIndex: number;
}

export interface ISetMeasurePayload {
  index: number;
  newMeasure: IColumn;

}

export interface ISortColumnPayload {
  columnType: draggableItemType;
  columnName: string;
  order: SortingOrderTypes | null;
  multiSort: boolean;
  Id: string;
}
export interface ISortDrilldownColumnPayload {
  columnType: draggableItemType;
  columnName: string;
  order: SortingOrderTypes | null;
  multiSort: boolean;
  key?: string;
  Id: string;
}

export interface DrilldownConfigMapping {
  [key: string]: MeasureListWithJoinId[]; // Array of string representing measure names
}
export interface DrilldownColumnConfigResponse {
  [key: string]: {
    defaultColumns: IColumn[];
    sortState: IDrilldownSorting[];
  }
}

export interface IDrilldownSorting {
  Field: string;
  Direction: SortingOrderTypes;
  Id: string;
}
export interface DrilldownColumnConfig {
  [key: string]: DrillDownColumnConfigObj;
}
export interface DrillDownColumnConfigObj{
  defaultColumns:IColumn[],
  sortState: IDrilldownSorting[];
  additionalDrilldownColumns?: IColumn[];
  isAdditionalColumnFetched: boolean;
}

export interface DrilldownFieldUpdationsAction{
  column: IColumn;
  groupingId: string;
  type: DrilldownFieldUpdationType;
}

export enum DrilldownFieldUpdationType {
  ADD='add',
  REMOVE='remove'
}

export interface MeasureIdentifier {
  Entity: string;
  JoinId: string;
}

export interface MeasureListWithJoinId {
  Id: string;
  Name: string;
  JoinId: string;
  Entity: Omit<FieldEntitiesType, FieldEntitiesType.Custom>;
}

export interface MeasureIdentifiers {
  [key: string]: MeasureIdentifier;
}

export interface MeasureIdentifierPayload {
  DefaultDrillDownColumnsInput: MeasureIdentifiers;
}

export interface MeasureListPayload {
  [key: string]: string[];
}
export interface IRawTemplatePayload{
  rawTemplateId: string;
  namespace: string;
  joins: string;
}

export interface ISaveReportPayload{
  data: IReportConfig,
  params?: ObjModel.Obj
}

export interface IDataSourceResponse{
  [key: string]: {
    Entity: FieldEntitiesType,
    NamespaceId: string,
    DisplayName: string,
    DefaultField: IDataSourceFieldType,
    AdditionalFields: IDataSourceFieldType[],
    IsQuery: boolean,
  };
}

export interface IDataSourceApiResponse{
    Entity: FieldEntitiesType,
    NamespaceId: string,
    DisplayName: string,
    DefaultField: string,
    AdditionalFields: IColumn[];
}

export interface IDataSourceInfo{
  dataSources: IDataSourceResponse
}

export interface IResetReportConfigPayload{
  joinsData: JoinItem[],
  primaryNamespace: string,
  reportConfig?: IReportConfig
  originalReportId?:string
  pageContext?: PageContext
}

export interface IDataSourceFieldType{
  DataType: ColumnDataTypes,
  DisplayName: string,
  SchemaName: string,
}

export interface IDataSourceSavePayload{
  ReportConfig: IReportConfig,
  NewDataSources: string[],
}

export interface INestedQueryDataSourceSavePayload {
  BuilderRequest: {
    Namespaces: string[];
  };
}

export interface IQueryBuilder {
  SelectedQueryData: ISelectedQueryInfo,
  SelectedQuery : string,
  DynamicQueryList: Array<IQueryInfo>,
  StaticQueryList: Array<IQueryInfo>,
  PreviewData: IQueryPreviewData,
  BreadcrumbData: Array<IBreadcrumbItem>,
  DataSourceMapping: IDataSourceMapping, // curent data source mapping config
  DataSourceMappingPayload: IDataSourceMapping, // final data source config that will be send as a part of payload
}

export interface ISelectedQueryInfo {
  additionalData: IQueryBuilderAdditionalInfo,
  updatedInfo: IQueryBuilderUpdatedInfo,
  NameSpace: string,
  QueryJoinRelation: IQueryJoinRelation,

}

export interface IQueryBuilderAdditionalInfo {
  AdditionalFilters: Array<ReportResponseModel.IFilterConfig>;
  AdditionalDimensions: Array<ReportResponseModel.IColumn>;
  AdditionalMeasures: Array<ReportResponseModel.IColumn>;
  AdditionalUserFieldFilters: Array<ReportResponseModel.IFilterConfig>;
  AdditionalUserFields: Array<ReportResponseModel.IColumn>;
  Fields:Array<IField>;
}

export interface IQueryBuilderUpdatedInfo {
  AppliedDimensions: IDimension[];
  AppliedMeasures: IColumn[];
  CalculatedColumns: Set<string>;
  ExpressionStore: ObjModel.ObjGeneric<ICustomExpression>;
  filterBuilder: IFilterBuilder;
  sortState: ISorting[];
  Paging: IPaging,
}

export interface IQueryInfo {
  Id: string,
  Name: string,
  LinkedQueryIds: Array<string>,
  ParentQueryId?: string,
  Type: ReportType,
  QueryPayload: IBuilder, // payload of apply-report-joins
  QueryJoinRelation: IQueryJoinRelation,
  IsDraft: boolean,
  QueryConfig?: IRawTemplate, // response of save-nested-joins api
  QueryDataInfo: ISelectedQueryInfo // latest changes done to query
}

export interface IDataSourceItems {
  Joins: Array<JoinItem>,
  PrimaryNamespace: string,
  JoinBlockIndexBeingEdited: number,
  isEditMode: boolean,
  isDataSourceSaved: boolean,
}

export interface IDataSourceMapping {
  [key: string]: IDataSourceItems;
}

export interface IQueryJoinRelation {
  JoinBlockindex: number;
  JoinBlockFieldSelection: DataSourceSelectionType;
  ParentQueryId: string;
}

export enum ReportType {
  Summary = 'Summary',
  List = 'List'
}
