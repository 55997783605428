export enum PendoClassNames {
  Filtereport ='se-track-filter-report',
  SaveAs = 'se-track-save-as',
  Save = 'se-track-save',
  None= '',
  AllReportsFilterV2= 'se-track-listing-report-section-all-report-filter-v2',
  AllPinnedFilterV2= 'se-track-listing-report-section-all-pinned-filter-v2',
  EditTagsSection= 'se-track-listing-edit-tags-section',
  TagsSection= 'se-track-listing-report-section-tags-section',
  AvailableFieldsBtn= 'se-track-additional-fields-btn',
  AvailableFiltersBtn= 'se-track-additional-filters-btn',
  MeasuresBtn= 'se-track-measures-btn',
  Filters= 'se-track-filters',
  Setting= 'se-track-visualization-settings',
  ZeroValues= 'se-track-zero-values',
  AddFilter= 'se-track-add-filter',
  DateRangeFilter= 'se-track-date-range',
  RemoveFilter= 'se-track-remove-filter',
  ResetFilters= 'se-track-reset-filters',
  ApplyFilters= 'se-track-apply-filters',
  PreviousPage= 'se-track-prev-page',
  NextPage= 'se-track-next-page',
  SortReport= 'se-track-sort-report',
  Pagination= 'se-track-pagination',
  DropDownFilters= 'se-track-dropdown-filters',
  MultiTextSearch= 'se-track-multi-text-search',
  UserDrilldown= 'se-track-user-drilldown',
  SortData= 'se-track-sort-data',
  BackBtn= 'se-track-back-btn',
  ApplySettings= 'se-track-apply-settings',
  RemoveField= 'se-track-remove-field',
  ShowZeroValues= 'se-track-show-zero-values',
  NewStateButton= 'se-track-new-state-btn',
  DeleteStateButton= 'se-track-delete-state-btn',
  ExportButton= 'se-track-quick-export',
  QuickCsvExport= 'se-track-quick-export-csv',
  QuickExcelExport= 'se-track-quick-export-xlsx',
  QuickPdfExport= 'se-track-quick-export-pdf',
  LargeExportButton= 'se-track-large-export',
  ExportPanel= 'se-track-export-panel-export',
  DownloadFile= 'se-track-export-panel-download',
  DataRefreshButton= 'se-track-data-refresh-button',
  RowGrouping= 'se-track-expand-row-grouping-fields',
  ColGrouping= 'se-track-expand-col-grouping-fields',
  ExpandDrilldown= 'se-track-expand-drilldown-fields',
  ExpandValues= 'se-track-expand-values',
  PngChartExport= 'se-track-png-chart-export',
  JpgChartExport= 'se-track-jpg-chart-export',
  PdfChartExport= 'se-track-pdf-chart-export',
  EditMeasure= 'se-track-edit-measure',
  EditDimension= 'se-track-edit-dimension',
  SaveMeasure= 'se-track-save-measure',
  CancelChangesMeasure= 'se-track-cancel-measure-changes',
  AddRowGroupingField= 'se-track-add-field-row-grouping',
  AddColGroupingField= 'se-track-add-field-col-grouping',
  AddValues= 'se-track-add-values',
  AddDrilldownField= 'se-track-add-drilldown-field',
  LSQLead= 'se-track-lsq-lead',
  LSQOpportunity= 'se-track-lsq-opportunity',
  AGgridCsvClick= 'se-track-click-excelcsv',
  AGgridPdfClick= 'se-track-click-pdfexp',
  AGgridExcelClick= 'se-track-click-excelexp',
  AGgridCopyClick= 'se-track-click-copy',
  ChartAutdrilldown= 'se-track-chart-autodrilldown',
  ChartSideBar= 'se-track-chart-btn-sidebar',
  DashboardKpiDrilldown= 'se-track-dashboard-kpi-drilldown',
  DashboardTableDrilldown= 'se-track-dashboard-table-drilldown',
  DashboardChartDrilldown= 'se-track-dashboard-chart-drilldown',
  DashboardRedirect= 'se-track-dashboard-redirect',
  DashboardFilters= 'se-track-dashboard-filters-pane',
  DashboardApplyFilters= 'se-track-dashboard-apply-filters',
  EmbededURLReport= 'se-track-embeded-url',
  EmbededURLdasboard= 'se-track-dashboard-embeded-url',
  PinReport= 'se-track-pin-report',
  UnpinReport= 'se-track-unpin-report',
  MyDashboard= 'se-track-mydashboard',
  LinkToDashboard= 'se-track-linkToDashboard',
  DashboardDateRange= 'se-track-dashboard-date-range',
  NoChart= 'se-track-no-chart',
  Column= 'se-track-column-chart',
  Bar= 'se-track-bar-chart',
  Area= 'se-track-area-chart',
  Line= 'se-track-line-chart',
  Pie= 'se-track-pie-chart',
  Donut= 'se-track-donut-chart',
  EditTags= 'se-track-edit-tags',
  DeleteReport= 'se-track-delete-report',
  DeleteDashboard= 'se-track-delete-dashboard',
  EditDashboard= 'se-track-edit-dashboard',
  CloneDashboard= 'se-track-clone-dashboard',
  ReportSection= 'se-track-listing-report-section',
  DashboardSection= 'se-track-listing-dashboard-section',
  Search= 'se-track-listing-search',
  EditTagsSave= 'se-track-listing-edit-tags-save',
  EditTagsCancel= 'se-track-listing-edit-tags-cancel',
  ProfileAddName= 'se-track-profile-add-name',
  CancelProfile= 'se-track-profile-cancel-profile',
  SaveProfile= 'se-track-profile-save-profile',
  ProfileCheckAccess= 'se-track-profile-check-access',
  ProfileChosenReportDashboard= 'se-track-profile-chosen-report-dashboard',
  CreateReportSidePanelNextButton= 'se-track-create-report-side-panel-next-button',
  CreateReportSidePanelCancelButton= 'se-track-create-report-side-panel-cancel-button',
  CreateReportSaveButton= 'se-track-create-report-save-button',
  CreateReportPreviewButton= 'se-track-create-report-preview-button',
  CreateReportCancelButton= 'se-track-create-report-cancel-button',
  CreateReportEditName= 'se-track-create-report-edit-name',
  CreateReportEditDesc= 'se-track-create-report-edit-desc',
  ReportSectionAllReport= 'se-track-report-section-all-report',
  ReportSectionPinnedReport= 'se-track-report-section-pinned-report',
  DashboardSectionAllDashboard= 'se-track-report-section-all-dashboard',
  DashboardSectionPinnedDashboard= 'se-track-report-section-pinned-dashboard',
  ListingSwitchProfile= 'se-track-listing-switch-profile',
  profilesSwitchProfile= 'se-track-profiles-switch-profile',
  profilesMySpaceSelection= 'se-track-profiles-my-space',
  DefaultProfileSelection= 'se-track-profile-default-selection',
  ProfilesDeleteProfile= 'se-track-delete-profile',
  ProfilesCreateNewProfile= 'se-track-create-new-profile',
  ProfileSharingCriteriaEveryone= 'se-track-profiles-sharing-criteria-everyone',
  ProfileSharingCriteriaNone= 'se-track-profiles-sharing-criteria-none',
  ProfileSharingCriteriaSpecific= 'se-track-profiles-sharing-criteria-specific',
  ProfilesReportSection= 'se-track-profiles-report-section',
  ProfilesDashboardSection= 'se-track-profiles-dashboard-section',
  ProfilesReportSectionReportSelection= 'se-track-profiles-report-section-report-selection',
  ProfilesReportSectionTagSelection= 'se-track-profiles-report-section-tag-selection',
  ProfilesReportPin= 'se-track-profiles-report-section-pin',
  ProfilesDashboardSectionReportSelection= 'se-track-profiles-dashboard-section-report-selection',
  ProfilesDashboardSectionTagSelection= 'se-track-profiles-dashboard-section-tag-selection',
  ProfilesDashboardPin= 'se-track-profiles-dashboard-section-pin',
  ProfilesDashboardSetAsHome= 'se-track-profiles-dashboard-set-as-home',
  DataSourceSelection= 'se-track-report-side-panel-data-source',
  ShowDataLabels= 'se-track-show-data-labels',
  Stack= 'se-track-stack-chart',
  HundredPercentStack= 'se-track-hundred-percent-stack-chart',
  SharedTooltip='se-track-shared-tooltips',
  ShowCategoryName = 'se-track-show-category-name',
  ShowSeriesLabels='se-track-show-series-labels',
  HideLegend = 'se-track-hide-legend',
  ChartPercent= 'se-track-chart-percent',
  DataSection= 'se-track-create-report-data-section',
  FilterSection= 'se-track-create-report-filter-section',
  ChartsSection= 'se-track-create-report-charts-section',
  RemoveValues='se-track-remove-value',
  BinningChange='se-track-change-binning',
  WarningIcon ='se-track-warning-icon',
  ChangeValueAggregation='se-track-values-change-agregation',
  ChangeValuesName='se-track-values-change-name',
  FilterEdit='se-track-filter-edit',
  FilterEditName='se-track-filter-edit-name',
  FilterEditApply='se-track-filter-edit-apply',
  ChangeFilterValue='se-track-change-filter-value',
  CreateDashboardButton='se-track-create-dashboard-btn',
  AddWidgetCenter='se-track-add-widget-center',
  AddWidgetButtonTop='se-track-add-widget-top',
  CreateDashboardCancelButton= 'se-track-dashboard-cancel-button',
  CreateDashboardSaveButton= 'se-track-dashboard-save-button',
  ExpandCollapseTagReportSelection = 'se-track-expand-collapse-tag',
  SelectReportRadioButton = 'se-track-select-report-button',
  AddWidgetReportListing = 'se-track-report-listing-add-widget',
  CancelReportListing = 'se-track-report-listing-cancel',
  WatchDemo='se-track-watch-demo',
  HowToCreateDashboard='se-track-how-to-create-dashboard',
  DashboardBuilderReportSearch='se-track-dashboard-report-search',
  DashboardBuilderAddName='se-track-dashboard-builder-add-name',
  DashboardBuilderAddDesc='se-track-dashboard-builder-add-desc',
  DashboardBuilderRemoveWidget='se-track-dashboard-builder-remove-widget',
  PinnedReports='se-track-report-pinned-reports',
  ReportThreeDotButton= 'se-track-report-three-dots',
  BookmarkReports= 'se-track-bookmark-report',

  AddCustomExp='se-track-create-expression-button',
  ExpNameField='se-track-display-name-field',
  ExpNameEditorField='se-track-expression-editor-field',
  ExpHelpDoc='se-track-help-doc-link',
  ExpCancelBtn='se-track-expression-cancel-button',
  ExpSaveBtn='se-track-expression-save-button',
  ExpCrossBtnClick='se-track-expression-cross-button',
  ExpAutoCompletionClick='se-track-expression-autocomplete-button',
  ExpActionMenuClick='se-track-expression-menu-button',
  ExpEditClick='se-track-expression-edit-button',
  ExpDelete='se-track-expression-delete-button',
  ExpApplyClick='se-track-expression-add-button',
  ExpDeleteConfirmBtn='se-track-expressiondelete-yes-button',
  ExpCancelDelete='se-track-expressiondelete-no-button',
  ExpDeleteCrossBtn='se-track-expressiondelete-cross-button',
  DashboardBuilderWidgetSearch='se-track-dashboard-widget-search',
  WidgetNameReport='se-track-widget-name-report',
  WidgetNameEmbed='se-track-widget-name-embed',
  UrlTextboxEmbed='se-track-embed-url-value',
  SelectVisualization='se-track-select-visualization',
  PreviewVisualizationSetting='se-track-preview-dasboard-visualization-settings',
  DashboardBuilderVisulizationColumnChart='se-track-dashboard-builder-visualization-columnchart',
  DashboardBuilderVisulizationTable='se-track-dashboard-builder-visualization-table',
  DashboardBuilderVisulizationBarChart='se-track-dashboard-builder-visualization-barchart',
  DashboardBuilderVisulizationAreaChart='se-track-dashboard-builder-visualization-areachart',
  DashboardBuilderVisulizationLineChart='se-track-dashboard-builder-visualization-linechart',
  DashboardBuilderVisulizationPieChart='se-track-dashboard-builder-visualization-piechart',
  DashboardBuilderVisulizationDonutChart='se-track-dashboard-builder-visualization-donutchart',
  DashboardBuilderVisulizationKpiChart='se-track-dashboard-builder-visualization-kpi',
  DashboardBuilderVisulizationAddWidget='se-track-dashboard-builder-visualization-add-widget',
  DashboardBuilderVisulizationCancelWidget='se-track-dashboard-builder-visualization-cancel-widget',
  DashboardBuilderEmbedUrlAddWidget='se-track-dashboard-builder-embedurl-add-widget',
  DashboardBuilderEmbedUrlCancelWidget='se-track-dashboard-builder-embedurl-cancel-widget',
  DashboardBuilderFilterButton = 'se-track-dashboard-filters',
  DashboardBuilderAddFilterButton = 'se-track-add-dashboard-filter',
  DashboardBuilderFilterName = 'se-track-dashboard-filter-name',
  DashboardBuilderFilterType = 'se-track-dashboard-filter-type',
  DashboardBuilderDateFilterType = 'se-track-dashboard-date-filter',
  DashboardBuilderDropdownFilterType = 'se-track-dashboard-dropdown-filter',
  DashboardBuilderStringFilterType = 'se-track-dashboard-string-filter',
  DashboardBuilderUserFilterType = 'se-track-dashboard-user-filter',
  DashboardBuilderSalesGroupFilterType = 'se-track-dashboard-salesgroup-filter',
  DashboardBuilderNextButton ='se-track-dashboard-filter-next',
  DashboardBuilderCancelButton ='se-track-dashboard-filter-cancel-name',
  DashboardBuilderPrimaryDropdownButton ='se-track-dashboard-filter-select-primary-dropdown',
  DashboardBuilderSearchWidget='se-track-dashboard-search-widget',
  DashboardBuilderWidgetCheckBox = 'se-track-dashboard-filter-select-widget',
  DashboardBuilderAddFilter ='se-track-dashboard-filter-add-filter',
  DashboardBuilderCancelFilter = 'se-track-dashboard-filter-cancel-filter',
  DashboardBuilderApplyAndSave ='se-track-dashboard-filter-apply-filter',
  DashboardBuilderRejectFilter = 'se-track-dashboard-filter-reject-changes',
  EditFilter = 'se-track-edit-filter',
  DashboardBuilderRemoveFilter = 'se-track-dashboard-remove-filter',
  EditReport= 'se-track-edit-report',
  FormatTypeNumber='se-track-formatType-number',
  FormatTypeCurrency='se-track-formatType-currency',
  FormatTypeDuration='se-track-formatType-duration',
  FormatTypeDate='se-track-formatType-date',
  FormatTypeTime='se-track-formatType-time',
  FormatDecimalOptionZero='se-track-format-decimal-zero',
  FormatDecimalOptionOne='se-track-format-decimal-one',
  FormatDecimalOptionTwo='se-track-format-decimal-two',
  FormatDecimalOptionThree='se-track-format-decimal-three',
  FormatNumericSeparatorOptionThousand='se-track-format-number-separator-thousand',
  FormatNumericSeparatorOptionHundred='se-track-format-number-separator-hundred',
  FormatNumericSeparatorOptionNone='se-track-format-number-separator-none',
  FormatDateOptionWeek='se-track-format-date-week',
  FormatDateOptionDow='se-track-format-date-dow',
  FormatDateOptionMonth='se-track-format-date-month',
  FormatDateOptionMDY='se-track-format-date-mdy',
  FormatDateOptionYMDHMS='se-track-format-date-ymdhms',
  FormatTimeOptionHMM='se-track-format-time-hmm',
  FormatTimeOptionMMSS='se-track-format-time-mmss',
  FormatTimeOptionHMMSS='se-track-format-time-hmmss',
  FormatTimeOptionHMMNoSuffix='se-track-format-time-hmm-no-suffix',
  FormatTimeOptionHMMSSNoSuffix='se-track-format-time-hmmss-no-suffix',
  FormatTimeOptionMMSSNoSuffix='se-track-format-time-mmss-no-suffix',
  FormatDateSeparatorOptionSlash='se-track-format-date-separator-slash',
  FormatDateSeparatorOptionHyphen='se-track-format-date-separator-hyphen',
  FormatDateSeparatorOptionColin='se-track-format-date-separator-colin',
  FormatDateSeparatorOptionComma='se-track-format-date-separator-comma',
  FormatDurationOptionMMSS='se-track-format-duration-mmss',
  FormatDurationOptionHHMM='se-track-format-duration-hhmm',
  FormatDurationOptionDHH='se-track-format-duration-dhh',
  FormatDurationOptionDAYS='se-track-format-duration-days',
  FormatDurationOptionHMMSS='se-track-format-duration-hmmss',
  FormatDurationOptionMMSSNoSuffix='se-track-format-duration-mmss-no-suffix',
  FormatDurationOptionHMMSSNoSuffix='se-track-format-duration-hmmss-no-suffix',
  FormatDurationOptionHHMMNoSuffix='se-track-format-duration-hhmm-no-suffix',
  AllReportFilter='se-track-listing-all-report-filter',
  SieraReportFilter='se-track-listing-siera-report-filter',
  OtherReportFilter='se-track-listing-other-report-filter',
  AllPinnedReportFilter='se-track-listing-all-pinned-report-filter',
  PinnedByMeReportFilter='se-track-listing-pinned-by-me-report-filter',
  PinnedForMeReportFilter='se-track-listing-pinned-for-me-report-filter',
  EditTagsDashboard = 'se-track-edit-tag-dashboard',
  ResetReportPage = 'se-track-reset-report',
  ResetDashboardPage = 'se-track-reset-dashboard',
  CopyReportEmbedUrl='se-track-copy-url-report',
  CopyDashboardEmbedUrl='se-track-copy-url-dashboard',
  SieraTour='se-track-siera-tour',
  ListingPageTour='se-pendo-productTour',
  GiveFeedback='se-pendo-giveFeedback',
  ReportAnIssue='se-pendo-reportAnIssue',
  ScheduleReport='se-track-schedule-report',
  ScheduleFilter='se-pendo-track-schedule-filter',
  ShowSubTotals='se-track-show-subTotals',
  ShowSummary='se-track-show-summary',
  ScheduleFrequency='se-track-schedule-frequency',
  ScheduleConfirm='se-track-schedule-confirm',
  ScheduleCancel='se-track-schedule-cancel',
  ManageSchedules='se-track-manage-schedule',
  ScheduleFiltersApply='se-track-manage-schedule-filters-apply',
  ScheduleFiltersCancel='se-track-manage-schedule-filters-cancel',
  ScheduleEdit='se-track-manage-schedule-edit',
  SwitchFilters = 'se-track-switch-filters',
  ScheduleListEmpty = 'se-track-manage-schedule-list-empty',
  ConfigureDrilldownToggle = 'se-track-drilldown-configure-toggle',
  AddDrilldownConfig = 'se-track-drilldown-configure-add',
  DrilldownAccordToggle = 'se-track-drilldown-accordion-toggle',
  DrilldownAccordRemoval = 'se-track-drilldown-accordion-remove',
  DrilldownAddValues = 'se-track-drilldown-values-add',
  DrilldownValuesRemoval = 'se-track-drilldown-values-remove',
  DrilldownFieldAddition = 'se-track-drilldown-fields-add',
  DrilldownFieldsRemoval = 'se-track-drilldown-fields-remove',
  DrilldownFieldsSort = 'se-track-drilldown-field-sort',
  DrilldownFieldEdit = 'se-track-drilldown-field-edit',
  DrilldownPaginationChange='se-track-drilldown-pagination',
  CustomAnalytics = 'se-pendo-customAnalytics',
  SwitchUserCnfBtn = 'se-pendo-switch-user-cnf-btn',
  SwitchUserCancelBtn = 'se-pendo-switch-user-cancel-btn',
  SwitchCrossBtn = 'se-pendo-switch-cross-btn',
  SwitchFields = 'se-pendo-switch-fields',
  SwitchDimension = 'se-pendo-switch-dimension',
  DataSource='se-pendo-data-source',
  RemoveQuery = 'se-pendo-remove-query',
  EditQuery = 'se-pendo-edit-query',
  EditDataSource='se-track-data-source-edit',
  SaveDataSource='se-track-data-source-save',
  NavigateBackFromDataSource='se-track-data-source-back',
  DataSourceRHSEntity='se-track-join-data-source-rhs-table',
  DataSourceLHSEntity='se-track-join-data-source-lhs-table',
  JoinTypeSelection = 'se-track-join-type',
  JoinTypeLeft = 'se-track-join-type-left-outer',
  JoinTypeRight = 'se-track-join-type-right-outer',
  JoinTypeInner = 'se-track-join-type-inner',
  JoinTypeFull = 'se-track-join-type-full-outer',
  DataSourceEntityAccordian = 'se-track-data-source-entity-accordion',
  DataSourceAddDataSource = 'se-track-join-data-source-add',
  DataSourceAddNewJoin = 'se-track-data-source-add-new-join',
  DataSourceEntityReplace = 'se-track-join-data-source-replace',
  DataSourceRHSField = 'se-track-join-ON-rhs-field',
  DataSourceLHSField = 'se-track-join-ON-lhs-field',
  DataSourceFieldReplace = 'se-track-join-ON-replace',
  DataSourceJoinBlockRemove = 'se-track-join-block-remove'
}

export const PendoChartVisualization = {
  ColumnChart: PendoClassNames.Column,
  LineChart: PendoClassNames.Line,
  AreaChart: PendoClassNames.Area,
  BarChart: PendoClassNames.Bar,
  PieChart: PendoClassNames.Pie,
  DonutChart: PendoClassNames.Donut,
  Table: PendoClassNames.NoChart,
};

export const PendoSelectedState = {
  Report: 'report',
  ReportBuilder: 'report-builder',
  Dashboard: 'dashboard',
  Listing: 'listing-page',
  DashboardBuilder: 'dashboard-builder',
  ReportEdit: 'report-edit',
};

export enum PendoFilterType {
  date='date-filter',
  string ='string-filter',
  dropdown='dropdown-filter'
}
