import API from 'services/api';
import {
  AuthResponseModel, ObjModel, ReportResponseModel,
} from 'core/models';
import { DynamicMeasureResponse, IDataRefreshStatus } from 'core/models/report-response';
import { AccessType, DataRefreshEntityTypes } from 'core/constants/report';
import { IAutoDrilldownV2Data } from 'core/models/report-redux';
import SieraResponse from 'core/models/siera-response';
import { IReportConfig } from 'core/models/report-builder/report-builder';
import { FeatureContext } from 'core/constants/common';

export class ReportService extends API {
  static baseURL = process.env.REACT_APP_DATA_BASE_URL || '';

  constructor() {
    super(ReportService.baseURL);
  }

  getReportConfig = async (params: ObjModel.Obj): Promise<SieraResponse<ReportResponseModel.IReportConfig>> => (
    this.genericResponseWrapper(await this.instance.get('reports/get-report-config', { params }))
  );

  getRawConfig = async (params: ObjModel.Obj): Promise<SieraResponse<IReportConfig>> => (
    this.genericResponseWrapper(await this.instance.get('reports/get-raw-config', { params }))
  );

  getReportData = async (criteria: ReportFetchCriteria) : Promise<ReportResponseModel.IReportData> => (
    this.responsify(await this.instance.post('reports/fetch-report', criteria))
  );

  getDynamicDimensions = async (reportId: string) : Promise<Array<ReportResponseModel.IColumn>> => (
    this.responsify(await this.instance.get('visualisation/get-additional-dimensions', {
      params: {
        reportId,
      },
    }))
  )

  getDynamicFilters = async (reportId: string) : Promise<Array<ReportResponseModel.IFilterConfig>> => (
    this.responsify(await this.instance.get('visualisation/get-additional-filters', {
      params: {
        reportId,
      },
    }))
  )

  getDynamicMeasures = async (reportId: string) : Promise<Array<DynamicMeasureResponse>> => (
    this.responsify(await this.instance.get('visualisation/get-additional-measures', {
      params: {
        reportId,
      },
    }))
  )

    getFetchDrilldownReport = async (criteria: ReportFetchCriteria, params?: ObjModel.Obj) : Promise<ReportResponseModel.IReportData> => (
      this.responsify(await this.instance.post('reports/fetch-drilldown-report', criteria, { params }))
    );

    getDrilldownState = async (stateID: string): Promise<IAutoDrilldownV2Data> => (
      this.responsify(await this.instance.get('reports/get-temp-drilldown-state', { params: { id: stateID } }))
    );

    getFetchDrilldownReportV2 = async (criteria: IAutoDrilldownV2Data, params?: ObjModel.Obj) : Promise<ReportResponseModel.IReportData> => (
      this.responsify(await this.instance.post('reports/fetch-drilldown-report-v2', criteria, { params }))
    );

    getExportData = async (criteria: ReportFetchCriteria, fileType:string) : Promise<ReportResponseModel.IExportData> => (
      this.responsify(await this.instance.post('reports/trigger-export', criteria, {
        params: {
          fileType,
        },
      }))
    );

    getExportDataV2 = async (criteria: IAutoDrilldownV2Data, fileType:string) : Promise<ReportResponseModel.IExportData> => (
      this.responsify(await this.instance.post('reports/trigger-export-v2', criteria, { params: { fileType } }))
    );

    getExportListData = async (): Promise<Array<ReportResponseModel.IExportData>> => (
      this.responsify(await this.instance.get('reports/get-export-list'))
    );

    getExportUsageStatus = async (): Promise<ReportResponseModel.IExportUsageData> => (
      this.responsifyGeneric<ReportResponseModel.IExportUsageData>(await this.instance.get('reports/get-export-usage'))
    );

    getExportListDataByIds = async (reportIds: Array<string>): Promise<Array<ReportResponseModel.IExportData>> => (
      this.responsify(await this.instance.get('reports/get-export-status', {
        params: {
          exportIds: reportIds.join(','),
        },
      }))
    );

    getRetryExportData = async (params: object) : Promise<string> => (
      this.responsify(await this.instance.post('reports/retry-export', params))
    );

    getDataRefreshDetails = async (params: object) : Promise<DataRefreshEntityTypes> => (
      this.responsify(await this.instance.get('reports/data-refresh-status', {
        params,
      }))
    );

   getDataRefreshStatus = async (params: object) : Promise<IDataRefreshStatus> => (
     this.responsify(await this.instance.get('reports/data-refresh-status-v2', {
       params,
     }))
   );

    accesscontrol = async (reportId: string, type: AccessType) => (
      this.responsifyGeneric<AuthResponseModel.Auth>(await this.instance.get('access-control/isAccessible', {
        params: {
          id: reportId,
          type,
        },
      }))
    )

    deleteReport = async (reportId: string) => (
      this.responsify(await this.instance.delete('reports/delete', {
        params: {
          reportId,
        },
      }))
    )
}

export interface ReportFetchCriteria {
  ReportId: string;
  Filters: any;
  Page:any;
  Sort:any
  Columns: Array<ReportResponseModel.IColumn>;
  FiltersMetadata?: ReportResponseModel.IFiltersMetaData;
  SelectedMeasure: string;
  SelectedRow: Array<SelectedRow>;
  DrilldownColumns?: Array<ReportResponseModel.IColumn>;
  FeatureContext?: FeatureContext;
}

export interface SelectedRow {
  Alias: string;
  Value: string;
  Entity: string;
  IsMasked: boolean;
  ReferredName?: string;
  ReferredValue?: any;
  BinningType?: string;
  Id: string;
}

export interface IPinnedReportsPayload {
  sort: string;
}

export interface ICategoryReportsPayload {
  CategoryId: string;
  sort: string;
}

export interface IPinReportPayload {
  ReportId: string;
  PersonalisationId: string;
  IsPinned: boolean;
}

export interface ICategoriesReorderPayload {
  CategoryIds: Array<string>;
}
