import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
};

const LinkIcon = ({ width, height, fill }: Props) => (
  <svg width={width} height={height} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill={fill} d="M7.33337 11.8334H4.66671C3.74448 11.8334 2.95837 11.5084 2.30837 10.8584C1.65837 10.2084 1.33337 9.42224 1.33337 8.50002C1.33337 7.5778 1.65837 6.79169 2.30837 6.14169C2.95837 5.49169 3.74448 5.16669 4.66671 5.16669H7.33337V6.50002H4.66671C4.11115 6.50002 3.63893 6.69446 3.25004 7.08335C2.86115 7.47224 2.66671 7.94446 2.66671 8.50002C2.66671 9.05558 2.86115 9.5278 3.25004 9.91669C3.63893 10.3056 4.11115 10.5 4.66671 10.5H7.33337V11.8334ZM5.33337 9.16669V7.83335H10.6667V9.16669H5.33337ZM8.66671 11.8334V10.5H11.3334C11.8889 10.5 12.3612 10.3056 12.75 9.91669C13.1389 9.5278 13.3334 9.05558 13.3334 8.50002C13.3334 7.94446 13.1389 7.47224 12.75 7.08335C12.3612 6.69446 11.8889 6.50002 11.3334 6.50002H8.66671V5.16669H11.3334C12.2556 5.16669 13.0417 5.49169 13.6917 6.14169C14.3417 6.79169 14.6667 7.5778 14.6667 8.50002C14.6667 9.42224 14.3417 10.2084 13.6917 10.8584C13.0417 11.5084 12.2556 11.8334 11.3334 11.8334H8.66671Z" />
  </svg>
);

LinkIcon.defaultProps = {
  width: 16,
  height: 17,
  fill: 'var(--secondaryColor)',
};

export default LinkIcon;
