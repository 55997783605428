/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IAddDynamicFilterPayload,
  IAutoDrilldownPayload,
  IAutoDrilldownV2Data,
  IDeleteDynamicFilterPayload,
  IDrilldownChangePayload,
  IFilterChangePayload,
  ILoadMoreDropdownOptionsPayload,
  IPaginationChangePayload,
  IReorderMeasurePayload,
  IReverseDrilldownPayload,
  ISearchDropdownOptionsPayload,
  ISelectedToolbarMenuItemPayload,
  ISetBinningPayload,
  ISetChartDataPayload,
  ISetGroupingDrilldownPayload,
  ISetGroupingPayload,
  ISetMeasureStatePayload,
  ISetReportInfoPayload,
  ISetShowReportPayload,
  ISetVisualizationPayload,
  ISetZeroAnchorPayload,
  ISortingChangePayload,
  IUpdateMeasurePayload,
  IUpdateTriggerExportPayload,
} from 'core/models/report-redux';
import { APIRequestStatus } from 'core/constants/redux';
import SieraResponse from 'core/models/siera-response';
import {
  DynamicMeasureResponse, IColumn, IDataRefresh, IDataRefreshStatus, IExportData, IExportUsageData, IField, IFilterConfig, IReportConfig, IReportData,
} from 'core/models/report-response';
import { ObjModel, ReportResponseModel } from 'core/models';
import { Auth } from 'core/models/auth-response';
import { SortingOrderTypes } from 'core/models/report-builder/report-builder';
import {
  getAppliedMeasures, getDefaultEntity, getIsReportBuilderSaveFormDisable, sortColumns,
} from 'core/utils/report.util';
import { ColumnModes, DataRefreshEntityTypes, ExportStatus } from 'core/constants/report';
import { getSaveSaveAsURL } from 'core/utils/common.utility';
import { isValidArray, isValidArrayAndNotEmpty } from 'components/feature/Report/ReportSidebar/common/helpers';
import { getEntityJoinIdForCustomMeasure } from 'components/feature/ReportBuilderV2/ReportBuilderDetail/DrilldownTabContent/drilldown.util';
import reportInitialState from './report-store.initial-state';
import {
  accesscontrol,
  deleteReport,
  getDataRefreshDetails,
  getDataRefreshStatus,
  getDynamicDimensions,
  getDynamicFilters,
  getDynamicMeasures,
  getExportData,
  getExportDataV2,
  getExportListDataByIds,
  getExportLists,
  getExportUsageStatus,
  getExpressionFields,
  getFetchDrilldownReportV2,
  getFiltersLoad,
  getRawConfig, getReportConfig, getReportData, getRetryExportDatagetRetryExportData, saveReportConfig,
} from './report-store.requests';
import { IReportState, ReportActionTypes } from './report-store.state';
import {
  dataRefreshDetailsLoadSuccess, dataRefreshStatusLoadSuccess, getDynamicDimensionOnDynamicDimensionLoadSuccess, getStateOnAddDynamicFilter,
  getStateOnApplyDimensions, getStateOnApplyingFilters, getStateOnAutodrilldownReportDataLoadSuccess, getStateOnDeleteDynamicFilter,
  getStateOnDrilldownChange, getStateOnFilterChange, getStateOnFilterLoadFailure, getStateOnFilterLoadRequest, getStateOnFilterLoadSuccess,
  getStateOnLoadMoreDropdownOptions, getStateOnMeasureReorder, getStateOnMeasureSetState, getStateOnMeasureUpdate, getStateOnPaginationChange,
  getStateOnReorderColumns, getStateOnReportConfigLoadSuccess, getStateOnReportDataLoadSuccess, getStateOnReverseDrilldown, getStateOnSearchDropdownOptions,
  getStateOnSetBinning, getStateOnSetGrouping, getStateOnSetGroupingDrilldown, getStateOnSetToolbarMenuItem, getStateOnSetZeroAnchor, getStateOnSortColumn,
  getStateOnToggleZeroAnchor, updateDynmaicMeasureStateOnSuccess,
} from './report-store.utils';

const reportStoreSlice = createSlice({
  name: 'report',
  initialState: reportInitialState,
  reducers: {
    [ReportActionTypes.AUTODRILLDOWN]: (state:IReportState, action: PayloadAction<IAutoDrilldownPayload>) => {
      const {
        selectedMeasure, selectedRowData, measureFieldAlias, rowPinnedClicked, clickedOnColumnTotal, clickedOnRowTotal, clickedOnSubTotal, clickedOnGroupedSubTotal,
      } = action.payload as IAutoDrilldownPayload;
      const selectedColumn = isValidArrayAndNotEmpty(state.columns) && state.columns.find((column:IColumn) => column.Id === selectedMeasure);
      const measureField = selectedMeasure ? getEntityJoinIdForCustomMeasure(state.fields, selectedColumn, getDefaultEntity(state?.savedReportConfig)) : null;
      if (selectedMeasure) {
        state.autodrilldownData = {
          selectedMeasure,
          selectedRowData,
          page: {
            PageSize: 25,
            PageIndex: 1,
          },
          columns: [],
          sort: [],
          isMultiSort: false,
          reportData: null,
          drilldownFields: null,
          measureFieldAlias,
          rowPinnedClicked,
          clickedOnColumnTotal,
          clickedOnRowTotal,
          clickedOnGroupedSubTotal,
          clickedOnSubTotal,
          selectedMeasureInfo: measureField,
        };
      } else {
        state.autodrilldownData = null;
        state.autoDrilldownStateID = '';
        state.autoDrilldownDataV2 = null;
        if (isValidArrayAndNotEmpty(state.dynamicDimensions)) {
          state.requestProcessing[ReportActionTypes.REPORT_DYNAMIC_DIMENSIONS_LOAD_REQUEST] = APIRequestStatus.Success;
        }
      }
    },
    [ReportActionTypes.AUTODRILLDOWN_REPORT_DATA_LOAD_REQUEST]: (state:IReportState) => {
      state.requestProcessing[ReportActionTypes.AUTODRILLDOWN_REPORT_DATA_LOAD_REQUEST] = APIRequestStatus.Processing;
      if (!state?.autodrilldownData?.drilldownFields) state.requestProcessing[ReportActionTypes.REPORT_DYNAMIC_DIMENSIONS_LOAD_REQUEST] = APIRequestStatus.Processing;
    },
    [ReportActionTypes.AUTODRILLDOWN_REPORT_DATA_LOAD_SUCCESS]: (state:IReportState, action: PayloadAction<IReportData>) => (getStateOnAutodrilldownReportDataLoadSuccess(state, action.payload)),

    [ReportActionTypes.AUTODRILLDOWN_REPORT_DATA_LOAD_FAILURE]: (state:IReportState) => {
      state.requestProcessing[ReportActionTypes.AUTODRILLDOWN_REPORT_DATA_LOAD_REQUEST] = APIRequestStatus.Failure;
    },
    [ReportActionTypes.AUTODRILLDOWN_V2]: (state:IReportState, action: PayloadAction<IAutoDrilldownV2Data>) => {
      state.autoDrilldownDataV2 = action.payload;
      state.showReport = true;
      state.autoDrilldownStateID = action?.payload?.SourceId;
    },
    [ReportActionTypes.APPLY_FILTERS]: (state:IReportState) => getStateOnApplyingFilters(state),
    [ReportActionTypes.FILTER_CHANGE]: (state: IReportState, action: PayloadAction<IFilterChangePayload>) => {
      const {
        filterId, filterValue, variables, useChangedFilters,
      } = action.payload as IFilterChangePayload;
      return getStateOnFilterChange(
        state,
        filterId,
        filterValue,
        variables,
        useChangedFilters,
      );
    },
    [ReportActionTypes.REORDER_COLUMNS]: (state: IReportState, action: PayloadAction<{columns: IColumn[], isAutodrilldown: boolean}>) => {
      const { columns, isAutodrilldown } = action.payload;
      return getStateOnReorderColumns(state, columns, isAutodrilldown);
    },
    [ReportActionTypes.PAGINATION_CHANGE]: (state:IReportState, action: PayloadAction<IPaginationChangePayload>) => {
      const {
        paginationKey, paginationValue, isAutodrilldown,
      } = action.payload as IPaginationChangePayload;
      return getStateOnPaginationChange(
        state,
        paginationKey,
        paginationValue,
        isAutodrilldown,
      );
    },

    [ReportActionTypes.SORTING_CHANGE]: (state:IReportState, action: PayloadAction<ISortingChangePayload>) => {
      const {
        columnType,
        columnName,
        order,
        multiSort,
        Id,
      } = action.payload as ISortingChangePayload;
      return getStateOnSortColumn(
        state,
        columnType,
        columnName,
        order,
        multiSort,
        Id,
      );
    },
    [ReportActionTypes.DRILLDOWN_SORTING_CHANGE]: (state:IReportState, action: PayloadAction<ISortingChangePayload>) => {
      const {
        columnType,
        columnName,
        order,
        multiSort,
        Id,
      } = action.payload as ISortingChangePayload;
      const drilldownFields = state?.autodrilldownData?.drilldownFields;
      const [sortState, hasChanged] = sortColumns(
        state.autodrilldownData.sort || [],
        state.activeDimensions,
        getAppliedMeasures(state.columns),
        columnType,
        columnName,
        order,
        !state.reportConfig.IsAugmented && multiSort,
        Id,
        drilldownFields,
        true,
      );
      if (!hasChanged) return;
      state.autodrilldownData.sort = sortState;
    },
    [ReportActionTypes.CHANGE_PIVOT_SORTING]: (state: IReportState, action: PayloadAction<SortingOrderTypes>) => {
      state.pivotSort = action.payload;
    },
    [ReportActionTypes.DRILLDOWN_CHANGE]: (state: IReportState, action: PayloadAction<IDrilldownChangePayload>) => {
      const {
        filterId, drilldownValue, user, variables, value,
      } = action.payload as IDrilldownChangePayload;
      return getStateOnDrilldownChange(
        state,
        filterId,
        drilldownValue,
        user,
        variables,
        value,
      );
    },
    [ReportActionTypes.REVERSE_DRILLDOWN]: (state: IReportState, action: PayloadAction<IReverseDrilldownPayload>) => {
      const {
        index,
      } = action.payload as IReverseDrilldownPayload;
      return getStateOnReverseDrilldown(state, index);
    },
    [ReportActionTypes.SET_TOOLBAR_MENU_ITEM]: (state:IReportState, action: PayloadAction<ISelectedToolbarMenuItemPayload>) => {
      const {
        index,
      } = action.payload as ISelectedToolbarMenuItemPayload;
      return getStateOnSetToolbarMenuItem(
        state,
        index,
      );
    },
    [ReportActionTypes.RESET_SHOW_OTHERS_COLUMN_FLAG]: (state: IReportState) => {
      state.showOthersInfoPopUp = false;
    },
    [ReportActionTypes.SET_GROUPING]: (state:IReportState, action: PayloadAction<ISetGroupingPayload>) => {
      const {
        sourceIndex, status, mode, destinationIndex, isSwapEnabled,
      } = action.payload as ISetGroupingPayload;
      return getStateOnSetGrouping(
        state,
        sourceIndex,
        status,
        mode,
        destinationIndex,
        isSwapEnabled,
      );
    },
    [ReportActionTypes.SET_ZERO_ANCHOR]: (state: IReportState, action: PayloadAction<ISetZeroAnchorPayload>) => {
      const {
        index,
      } = action.payload as ISetZeroAnchorPayload;
      return getStateOnSetZeroAnchor(
        state,
        index,
      );
    },
    [ReportActionTypes.APPLY_DIMENSIONS]: (state:IReportState, action: PayloadAction<number>) => getStateOnApplyDimensions(state, action.payload),
    [ReportActionTypes.SET_MEASURE_STATE]: (state: IReportState, action: PayloadAction<ISetMeasureStatePayload>) => {
      const {
        sourceIndex, status,
      } = action.payload as ISetMeasureStatePayload;
      return getStateOnMeasureSetState(
        state,
        sourceIndex,
        status,
      );
    },
    [ReportActionTypes.REORDER_MEASURE]: (state:IReportState, action: PayloadAction<IReorderMeasurePayload>) => {
      const {
        sourceIndex, destinationIndex,
      } = action.payload as IReorderMeasurePayload;
      return getStateOnMeasureReorder(
        state,
        sourceIndex,
        destinationIndex,
      );
    },
    [ReportActionTypes.UPDATE_MEASURE]: (state: IReportState, action: PayloadAction<IUpdateMeasurePayload>) => {
      const { index, newMeasure } = action.payload as IUpdateMeasurePayload;
      return getStateOnMeasureUpdate(state, index, newMeasure);
    },
    [ReportActionTypes.TOGGLE_ZERO_ANCHORS]: (state:IReportState) => getStateOnToggleZeroAnchor(state),
    [ReportActionTypes.SEARCH_DROPDOWN_OPTIONS]: (state: IReportState, action: PayloadAction<ISearchDropdownOptionsPayload>) => {
      const {
        filterId, searchKey,
      } = action.payload as ISearchDropdownOptionsPayload;
      return getStateOnSearchDropdownOptions(
        state,
        filterId,
        searchKey,
      );
    },
    [ReportActionTypes.LOAD_MORE_DROPDOWN_OPTIONS]: (state: IReportState, action: PayloadAction<ILoadMoreDropdownOptionsPayload>) => {
      const {
        filterId,
      } = action.payload as ILoadMoreDropdownOptionsPayload;
      return getStateOnLoadMoreDropdownOptions(
        state,
        filterId,
      );
    },
    [ReportActionTypes.ADD_DYNAMIC_FILTER]: (state: IReportState, action: PayloadAction<IAddDynamicFilterPayload>) => {
      const {
        index,
      } = action.payload as IAddDynamicFilterPayload;
      return getStateOnAddDynamicFilter(
        state,
        index,
      );
    },
    [ReportActionTypes.DELETE_DYNAMIC_FILTER]: (state: IReportState, action: PayloadAction<IDeleteDynamicFilterPayload>) => {
      const {
        filterId,
      } = action.payload as IDeleteDynamicFilterPayload;
      return getStateOnDeleteDynamicFilter(
        state,
        filterId,
      );
    },
    [ReportActionTypes.RESET]: () => reportInitialState,
    [ReportActionTypes.SET_BINNING]: (state: IReportState, action: PayloadAction<ISetBinningPayload>) => {
      const { index, value } = action.payload as ISetBinningPayload;
      return getStateOnSetBinning(state, index, value);
    },
    [ReportActionTypes.SET_GROUPING_DRILLDOWN]: (state: IReportState, action: PayloadAction<ISetGroupingDrilldownPayload>) => {
      const {
        sourceIndex, status, destinationIndex, isSwapEnabled,
      } = action.payload as ISetGroupingDrilldownPayload;
      return getStateOnSetGroupingDrilldown(
        state,
        sourceIndex,
        status,
        destinationIndex,
        isSwapEnabled,
      );
    },
    [ReportActionTypes.UPDATE_TRIGGER_EXPORT]: (state: IReportState, action: PayloadAction<IUpdateTriggerExportPayload>) => {
      const {
        exportFormatType,
      } = action.payload as IUpdateTriggerExportPayload;
      state.triggerExport = exportFormatType;
    },
    [ReportActionTypes.SET_REPORT_INFO]: (state:IReportState, action: PayloadAction<ISetReportInfoPayload>) => {
      const { displayName, description, isSaveAs } = action.payload as ISetReportInfoPayload;
      if (isSaveAs) { // update name and description for saveas properties
        state.reportNameSaveAs = displayName;
        state.reportDescriptionSaveAs = description;
        state.isFormDisabled = getIsReportBuilderSaveFormDisable(displayName, description);
      } else {
        state.reportName = displayName;
        state.reportDescription = description;
      }
    },
    [ReportActionTypes.SET_VISUALIZATION]: (state:IReportState, action: PayloadAction<ISetVisualizationPayload>) => {
      const { type, attributes } = action.payload as ISetVisualizationPayload;
      state.Visualization.type = type;
      state.Visualization.attributes = attributes;
      state.enablesSaveAs = true;
    },
    [ReportActionTypes.SET_SHOW_REPORT]: (state:IReportState, action: PayloadAction<ISetShowReportPayload>) => {
      const { showReport } = action.payload as ISetShowReportPayload;
      return {
        ...state,
        showReport,
      };
    },
    [ReportActionTypes.DELETE_REPORT]: (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.DELETE_REPORT] = APIRequestStatus.Processing;
    },
    [ReportActionTypes.SET_APPLY_CLICK_COUNT]: (state: IReportState, action: PayloadAction<number>) => {
      state.applyClickCount += action.payload;
    },
    [ReportActionTypes.SET_APPLY_CLICK_DRILLDOWN_COUNT]: (state: IReportState, action: PayloadAction<number>) => {
      state.applyClickCountDrilldown += action.payload;
    },
    [ReportActionTypes.SET_VISUALIZATION_SELECTION]: (state: IReportState, action: PayloadAction<ISetChartDataPayload>) => {
      const { type, attributeKey, attributes } = action.payload;
      state.selectedChartData.type = type;
      state.selectedChartData.attributeKey = attributeKey;
      state.selectedChartData.attributes = attributes;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReportConfig.pending, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.REPORT_CONFIG_LOAD_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getReportConfig.fulfilled, (state: IReportState, action: PayloadAction<SieraResponse<IReportConfig>, string, ObjModel.Obj>) => {
      const { data, status } = action.payload;
      state = getStateOnReportConfigLoadSuccess(state, data, action.meta.arg.variables, status);
      state.requestProcessing[ReportActionTypes.REPORT_CONFIG_LOAD_REQUEST] = APIRequestStatus.Success;
      state.reportId = action.meta.arg.reportId;
    });
    builder.addCase(getReportConfig.rejected, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.REPORT_CONFIG_LOAD_REQUEST] = APIRequestStatus.Failure;
    });

    builder.addCase(getReportData.pending, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.REPORT_DATA_LOAD_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getReportData.fulfilled, (state: IReportState, action: PayloadAction<IReportData>) => {
      state = getStateOnReportDataLoadSuccess(state, action.payload);
      state.requestProcessing[ReportActionTypes.REPORT_DATA_LOAD_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(getReportData.rejected, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.REPORT_DATA_LOAD_REQUEST] = APIRequestStatus.Failure;
    });

    builder.addCase(getRawConfig.pending, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.GET_RAW_CONFIG_LOAD_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getRawConfig.fulfilled, (state: IReportState, action: PayloadAction<SieraResponse<any>>) => {
      const reportConfig = { ...action.payload.data, Columns: action?.payload?.data?.Columns?.filter((col: IColumn) => !(col?.Props?.Measure?.IsCustomExpression && col?.Props?.Mode === ColumnModes.NotApplied)) || [] };
      // to remove additional custom measures from config
      state.savedReportConfig = reportConfig;
      state.requestProcessing[ReportActionTypes.GET_RAW_CONFIG_LOAD_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(getRawConfig.rejected, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.GET_RAW_CONFIG_LOAD_REQUEST] = APIRequestStatus.Failure;
    });

    builder.addCase(getDynamicDimensions.pending, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.REPORT_DYNAMIC_DIMENSIONS_LOAD_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getDynamicDimensions.fulfilled, (state: IReportState, action: PayloadAction<IColumn[], string, any>) => {
      const selectedMeasure = action?.meta?.arg?.measureFieldInfo;
      state = getDynamicDimensionOnDynamicDimensionLoadSuccess(state, action.payload, selectedMeasure);
    });
    builder.addCase(getDynamicDimensions.rejected, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.REPORT_DYNAMIC_DIMENSIONS_LOAD_REQUEST] = APIRequestStatus.Failure;
    });

    builder.addCase(getDynamicMeasures.pending, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.REPORT_DYNAMIC_MEASURES_LOAD_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getDynamicMeasures.fulfilled, (state: IReportState, action: PayloadAction<DynamicMeasureResponse[]>) => {
      state = updateDynmaicMeasureStateOnSuccess(state, action.payload);
      state.requestProcessing[ReportActionTypes.REPORT_DYNAMIC_MEASURES_LOAD_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(getDynamicMeasures.rejected, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.REPORT_DYNAMIC_MEASURES_LOAD_REQUEST] = APIRequestStatus.Failure;
    });

    builder.addCase(getFetchDrilldownReportV2.pending, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.AUTODRILLDOWN_REPORTV2_DATA_LOAD_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getFetchDrilldownReportV2.fulfilled, (state: IReportState, action: PayloadAction<any>) => {
      state = getStateOnAutodrilldownReportDataLoadSuccess(state, action.payload);
      state.requestProcessing[ReportActionTypes.AUTODRILLDOWN_REPORTV2_DATA_LOAD_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(getFetchDrilldownReportV2.rejected, (state: IReportState) => {
      state.autoDrilldownStateID = '';
      state.autoDrilldownDataV2 = null;
      state.requestProcessing[ReportActionTypes.AUTODRILLDOWN_REPORTV2_DATA_LOAD_REQUEST] = APIRequestStatus.Failure;
    });

    builder.addCase(getDataRefreshDetails.pending, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.DATA_REFRESH_DETAILS_LOAD_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getDataRefreshDetails.fulfilled, (state: IReportState, action: PayloadAction<DataRefreshEntityTypes, string, ObjModel.Obj>) => {
      state = dataRefreshDetailsLoadSuccess(state, action.payload, action?.meta?.arg?.isPolling);
      state.requestProcessing[ReportActionTypes.DATA_REFRESH_DETAILS_LOAD_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(getDataRefreshDetails.rejected, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.DATA_REFRESH_DETAILS_LOAD_REQUEST] = APIRequestStatus.Failure;
    });

    builder.addCase(getDataRefreshStatus.pending, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.DATA_REFRESH_STATUS_LOAD_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getDataRefreshStatus.fulfilled, (state: IReportState, action: PayloadAction<IDataRefreshStatus, string, ObjModel.Obj>) => {
      state = dataRefreshStatusLoadSuccess(state, action.payload, action?.meta?.arg?.isPolling);
      state.requestProcessing[ReportActionTypes.DATA_REFRESH_STATUS_LOAD_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(getDataRefreshStatus.rejected, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.DATA_REFRESH_STATUS_LOAD_REQUEST] = APIRequestStatus.Failure;
    });

    builder.addCase(accesscontrol.pending, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.ACCESS_CONTROL_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(accesscontrol.fulfilled, (state: IReportState, action: PayloadAction<Auth>) => {
      state.accessStatusCode = action.payload.statusCodes;
      state.requestProcessing[ReportActionTypes.ACCESS_CONTROL_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(accesscontrol.rejected, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.ACCESS_CONTROL_REQUEST] = APIRequestStatus.Failure;
    });

    builder.addCase(getFiltersLoad.pending, (state: IReportState, action: PayloadAction<any, string, ObjModel.Obj>) => getStateOnFilterLoadRequest(state, action.meta.arg.filterId, action.meta.arg.variables, action.meta.arg.isLazyLoad));
    builder.addCase(getFiltersLoad.fulfilled, (state: IReportState, action: PayloadAction<any, string, ObjModel.Obj>) => getStateOnFilterLoadSuccess(state, action.meta.arg.filterId, action.payload));
    builder.addCase(getFiltersLoad.rejected, (state: IReportState, action: PayloadAction<any, string, ObjModel.Obj>) => getStateOnFilterLoadFailure(state, action.meta.arg.filterId));

    builder.addCase(deleteReport.pending, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.DELETE_REPORT] = APIRequestStatus.Processing;
    });
    builder.addCase(deleteReport.fulfilled, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.DELETE_REPORT] = APIRequestStatus.Success;
    });
    builder.addCase(deleteReport.rejected, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.DELETE_REPORT] = APIRequestStatus.Failure;
    });

    builder.addCase(saveReportConfig.pending, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.SAVE_REPORT_CONFIG_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(saveReportConfig.fulfilled, (state: IReportState, action:PayloadAction<any, string, ObjModel.Obj>) => {
      const { isMarvin, isEmbedded } = action.meta.arg;
      window.open(getSaveSaveAsURL(isMarvin, isEmbedded, action.payload.data), '_self');
      state.requestProcessing[ReportActionTypes.SAVE_REPORT_CONFIG_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(saveReportConfig.rejected, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.SAVE_REPORT_CONFIG_REQUEST] = APIRequestStatus.Failure;
    });

    builder.addCase(getExportLists.pending, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.EXPORT_LIST_LOAD_REQUEST] = APIRequestStatus.Processing;
      state.exportListData = [];
    });
    builder.addCase(getExportLists.fulfilled, (state: IReportState, action:PayloadAction<IExportData[]>) => {
      state.requestProcessing[ReportActionTypes.EXPORT_LIST_LOAD_REQUEST] = APIRequestStatus.Success;
      state.exportListData = action.payload || [];
    });
    builder.addCase(getExportLists.rejected, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.EXPORT_LIST_LOAD_REQUEST] = APIRequestStatus.Failure;
    });

    builder.addCase(getExportUsageStatus.pending, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.EXPORT_USAGE_STATUS_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getExportUsageStatus.fulfilled, (state: IReportState, action: PayloadAction<IExportUsageData>) => {
      const { TotalDailyExports, ExportsConsumed } = action.payload;
      state.requestProcessing[ReportActionTypes.EXPORT_USAGE_STATUS_REQUEST] = APIRequestStatus.Success;
      state.totalDailyExports = TotalDailyExports;
      state.exportsConsumed = ExportsConsumed;
      state.isDailyLimitReached = TotalDailyExports === ExportsConsumed;
    });
    builder.addCase(getExportUsageStatus.rejected, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.EXPORT_USAGE_STATUS_REQUEST] = APIRequestStatus.Failure;
    });
    builder.addCase(getExportListDataByIds.pending, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.EXPORT_LIST_LOAD_BY_IDS_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getExportListDataByIds.fulfilled, (state: IReportState, action: PayloadAction<IExportData[]>) => {
      const exportList:IExportData[] = action.payload;
      const inProgressExportIds = isValidArrayAndNotEmpty(exportList) && exportList.map((item:IExportData) => item.ExportID);
      const currentExportListData = state.exportListData;
      const exportListDataUpdatedWithInprogressData = currentExportListData?.map((item:IExportData) => (inProgressExportIds.includes(item.ExportID) ? exportList?.find((i) => i.ExportID === item.ExportID) : item));
      state.exportListData = exportListDataUpdatedWithInprogressData;
      state.requestProcessing[ReportActionTypes.EXPORT_LIST_LOAD_BY_IDS_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(getExportListDataByIds.rejected, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.EXPORT_LIST_LOAD_BY_IDS_REQUEST] = APIRequestStatus.Failure;
    });

    builder.addCase(getRetryExportDatagetRetryExportData.pending, (state: IReportState, action: PayloadAction<string, string, ObjModel.Obj>) => {
      const newExportListData = state.exportListData;
      const indexOfReport = isValidArrayAndNotEmpty(newExportListData) && newExportListData.findIndex((rep:IExportData) => rep.ExportID === action.meta.arg);
      if (newExportListData[indexOfReport]) {
        newExportListData[indexOfReport].Status = ExportStatus.InProgress;
        state.exportListData = newExportListData;
        state.requestProcessing[ReportActionTypes.RETRY_EXPORT_REQUEST] = APIRequestStatus.Processing;
      }
    });
    builder.addCase(getRetryExportDatagetRetryExportData.fulfilled, (state: IReportState, action: PayloadAction<string, string, ObjModel.Obj>) => {
      const newExportListData = state.exportListData;
      const indexOfReport = isValidArrayAndNotEmpty(newExportListData) && newExportListData.findIndex((rep) => rep.ExportID === action.meta.arg.exportID);
      if (newExportListData[indexOfReport]) {
        newExportListData[indexOfReport].Status = ExportStatus.Failed;
      }
      state.exportListData = newExportListData;
      state.requestProcessing[ReportActionTypes.RETRY_EXPORT_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(getRetryExportDatagetRetryExportData.rejected, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.RETRY_EXPORT_REQUEST] = APIRequestStatus.Failure;
    });

    builder.addCase(getExportData.pending, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.EXPORT_DATA_LOAD_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getExportData.fulfilled, (state: IReportState, action: PayloadAction<IExportData>) => {
      const exportData = action.payload;
      if (isValidArray(state.exportListData)) {
        state.exportListData.push(exportData);
      }
      state.requestProcessing[ReportActionTypes.EXPORT_DATA_LOAD_REQUEST] = APIRequestStatus.Success;
      // since we added new export request to the queue we need to increment export consumed count
      state.exportsConsumed += 1;
      state.isDailyLimitReached = state.totalDailyExports === state.exportsConsumed;

      state.requestProcessing[ReportActionTypes.EXPORT_DATA_LOAD_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(getExportData.rejected, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.EXPORT_DATA_LOAD_REQUEST] = APIRequestStatus.Failure;
    });

    builder.addCase(getDynamicFilters.pending, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.DYNAMIC_FILTERS_LOAD_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getDynamicFilters.fulfilled, (state: IReportState, action: PayloadAction<IFilterConfig[]>) => {
      state.dynamicFilters = action.payload;
      state.dynamicFilters = [...state.reportConfig.Filters.filter((item:IFilterConfig) => item.IsAdditional), ...action.payload];
      state.requestProcessing[ReportActionTypes.DYNAMIC_FILTERS_LOAD_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(getDynamicFilters.rejected, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.DYNAMIC_FILTERS_LOAD_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getExportDataV2.pending, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.DASHBOARD_REDIRECTED_EXPORT_DATA_LOAD_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getExportDataV2.fulfilled, (state: IReportState, action: PayloadAction<IExportData>) => {
      const exportData = action.payload;
      if (isValidArray(state.exportListData)) {
        state.exportListData.push(exportData);
      }
      // since we added new export request to the queue we need to increment export consumed count
      state.exportsConsumed += 1;
      state.isDailyLimitReached = state.totalDailyExports === state.exportsConsumed;

      state.requestProcessing[ReportActionTypes.DASHBOARD_REDIRECTED_EXPORT_DATA_LOAD_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(getExportDataV2.rejected, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.DASHBOARD_REDIRECTED_EXPORT_DATA_LOAD_REQUEST] = APIRequestStatus.Failure;
    });

    builder.addCase(getExpressionFields.pending, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.GET_EXPRESSION_FIELDS] = APIRequestStatus.Processing;
    });
    builder.addCase(getExpressionFields.fulfilled, (state: IReportState, action: PayloadAction<Array<IField>, string, ObjModel.Obj>) => {
      state.fields = action.payload;
      state.requestProcessing[ReportActionTypes.GET_EXPRESSION_FIELDS] = APIRequestStatus.Success;
    });
    builder.addCase(getExpressionFields.rejected, (state: IReportState) => {
      state.requestProcessing[ReportActionTypes.GET_EXPRESSION_FIELDS] = APIRequestStatus.Failure;
    });
  },
});

export const {
  [ReportActionTypes.AUTODRILLDOWN]: reportAutodrilldown,
  [ReportActionTypes.AUTODRILLDOWN_V2]: getTempAutodrilldownStateId,
  [ReportActionTypes.APPLY_FILTERS]: applyReportFilters,
  [ReportActionTypes.PAGINATION_CHANGE]: reportPaginationChange,
  [ReportActionTypes.SORTING_CHANGE]: reportSortingChange,
  [ReportActionTypes.CHANGE_PIVOT_SORTING]: reportPivotSortChange,
  [ReportActionTypes.REVERSE_DRILLDOWN]: reportReverseDrilldown,
  [ReportActionTypes.RESET_SHOW_OTHERS_COLUMN_FLAG]: reportResetOthersColumn,
  [ReportActionTypes.SET_GROUPING]: reportSetGrouping,
  [ReportActionTypes.SET_ZERO_ANCHOR]: reportSetZeroAnchor,
  [ReportActionTypes.APPLY_DIMENSIONS]: reportApplyDimensions,
  [ReportActionTypes.SET_MEASURE_STATE]: reportSetMeasureState,
  [ReportActionTypes.REORDER_MEASURE]: reportReorderMeasure,
  [ReportActionTypes.UPDATE_MEASURE]: reportUpdateMeasure,
  [ReportActionTypes.TOGGLE_ZERO_ANCHORS]: reportToggleZeroAnchor,
  [ReportActionTypes.SEARCH_DROPDOWN_OPTIONS]: reportSearchDropdownOptions,
  [ReportActionTypes.LOAD_MORE_DROPDOWN_OPTIONS]: reportLoadMoreOptions,
  [ReportActionTypes.ADD_DYNAMIC_FILTER]: reportAddDynamicFilter,
  [ReportActionTypes.DELETE_DYNAMIC_FILTER]: removeDynamicFilter,
  [ReportActionTypes.RESET]: reportReset,
  [ReportActionTypes.SET_BINNING]: reportSetBinning,
  [ReportActionTypes.SET_GROUPING_DRILLDOWN]: reportSetGroupingDrilldown,
  [ReportActionTypes.UPDATE_TRIGGER_EXPORT]: reportUpdateTriggerExport,
  [ReportActionTypes.SET_REPORT_INFO]: setReportDetails,
  [ReportActionTypes.SET_VISUALIZATION]: reportSetVisualization,
  [ReportActionTypes.SET_SHOW_REPORT]: setShowReport,
  [ReportActionTypes.DRILLDOWN_CHANGE]: reportDrilldownChange,
  [ReportActionTypes.SET_TOOLBAR_MENU_ITEM]: reportSetToolBarMenuItem,
  [ReportActionTypes.FILTER_CHANGE]: reportFilterChange,
  [ReportActionTypes.AUTODRILLDOWN_REPORT_DATA_LOAD_REQUEST]: loadAutodrilldown,
  [ReportActionTypes.AUTODRILLDOWN_REPORT_DATA_LOAD_SUCCESS]: autodrilldownLoadSuccess,
  [ReportActionTypes.AUTODRILLDOWN_REPORT_DATA_LOAD_FAILURE]: autodrilldownLoadFailure,
  [ReportActionTypes.REORDER_COLUMNS]: reorderColumns,
  [ReportActionTypes.DRILLDOWN_SORTING_CHANGE]: drilldownSortingChange,
  [ReportActionTypes.DELETE_REPORT]: resetDeleteReportRequestStatus,
  [ReportActionTypes.SET_APPLY_CLICK_COUNT]: setApplyClickCount,
  [ReportActionTypes.SET_APPLY_CLICK_DRILLDOWN_COUNT]: setApplyClickCountDrilldown,
  [ReportActionTypes.SET_VISUALIZATION_SELECTION]: setSelectedChartData,

} = reportStoreSlice.actions;

export default reportStoreSlice;
