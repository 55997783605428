import { format, getDay, getWeek } from 'date-fns';

import {
  BooleanFormatters,
  DateBinningFormatters,
  DateFormatters,
  daysOfWeek,
  DurationFormatter,
  Formatters,
  months,
  StringFormatters,
  TimeFormatters,
} from 'core/constants/formatters';
import { ReportResponseModel } from 'core/models';
import { ColumnRenderTypes, DefaultTimezone, InternationalDateLineWest } from 'core/constants/report';
import { DateConversionFormat, TimeConversionFormat } from 'core/constants/date';
import { isValidDateString } from './date.util';

const moment = require('moment-timezone');

// currenncyFormatter
export const currencyFormatter = (value:number, decimal:number, symbol:string, seperator:number, isCharts: boolean) => {
  if (isCharts) {
    return value;
  }
  const currency = value.toFixed(decimal);
  const formattCurrency = seperatorFn(currency, seperator);
  return `${symbol}${formattCurrency}`;
};

// numberFormatter
export const numberFormatter = (value:number, decimal:number, seperator:number) => {
  const newValue = value.toFixed(decimal);
  const formattNumber = seperatorFn(newValue, seperator);
  return `${formattNumber}`;
};

// intNumberFormatter
export const intNumberFormatter = (value:number, decimal:number) => Number(value.toFixed(decimal));

// string formatter
export const stringFormatter = (value:string, stringtype:string) => {
  const returnedValue = value;
  if (stringtype === StringFormatters.LowerCase) {
    return returnedValue.toLowerCase();
  }
  if (stringtype === StringFormatters.UpperCase) {
    return returnedValue.toUpperCase();
  }
  if (stringtype === StringFormatters.CapitalCase) {
    return returnedValue.charAt(0).toUpperCase() + returnedValue.slice(1);
  }
  return returnedValue;
};

const booleanFormatters: any = {
  [BooleanFormatters.YesNo]: (value: boolean) => (value ? BooleanFormatters.Yes : BooleanFormatters.No),
  [BooleanFormatters.TrueFalse]: (value: boolean) => (value ? BooleanFormatters.True : BooleanFormatters.False),
  [BooleanFormatters.OnOff]: (value: boolean) => (value ? BooleanFormatters.On : BooleanFormatters.Off),
  [BooleanFormatters.EnabledDisabled]: (value: boolean) => (value ? BooleanFormatters.Enabled : BooleanFormatters.Disabled),
  [BooleanFormatters.OneZero]: (value: boolean) => (value ? 1 : 0),
  [BooleanFormatters.CustomDefined]: (value: boolean, values: any) => (value ? values.true : values.false),
};

// Boolean Formatter
export const booleanFormatter = (value:boolean, valuetype:string, values?:any) => {
  const formatter = booleanFormatters[valuetype];
  return formatter ? formatter(value, values) : value;
};

// Time Formatter
export const timeFormatter = (value:string, valuetype:string, seperator:string) => {
  if (value) {
    const input = value.split('T')[1];
    const [h, mm, updatedss] = input.split(':');
    const ss = updatedss.slice(0, 2);
    if (valuetype === TimeFormatters.HMM) {
      const returnedValue = `${h}h${seperator}${mm}m`;
      return returnedValue;
    }
    if (valuetype === TimeFormatters.MMSS) {
      const returnedValue = `${mm}m${seperator}${ss}s`;
      return returnedValue;
    }
    if (valuetype === TimeFormatters.HMMSS) {
      const returnedValue = `${h}h${seperator}${mm}m${seperator}${ss}s`;
      return returnedValue;
    }
    if (valuetype === TimeFormatters.HMMNoSuffix) {
      const returnedValue = `${h}${seperator}${mm}`;
      return returnedValue;
    }
    if (valuetype === TimeFormatters.MMSSNoSuffix) {
      const returnedValue = `${mm}${seperator}${ss}`;
      return returnedValue;
    }

    if (valuetype === TimeFormatters.HMMSSNoSuffix) {
      const returnedValue = `${h}${seperator}${mm}${seperator}${ss}`;
      return returnedValue;
    }
  }

  return value;
};

// Date formatters
export const dateFormatter = (value:string, valuetype:string, seperator:string, userTimeZone?:string, renderType?: ColumnRenderTypes) => {
  if (!isValidDateString(value)) {
    return value;
  }
  const [year, month, day] = value?.toString().substring(0, 10).split('-');

  if (valuetype === DateFormatters.WEEK) {
    const week = getWeek(new Date(Number(year), Number(month) - 1, Number(day)), {
      weekStartsOn: 1, firstWeekContainsDate: 1, // per mode 9 of https://clickhouse.com/docs/en/sql-reference/functions/date-time-functions#toweek
    });
    return `Week ${week}`;
  }
  if (valuetype === DateFormatters.DAY) {
    const dayOfWeek = getDay(new Date(Number(year), Number(month) - 1, Number(day)));
    return daysOfWeek[(dayOfWeek + 6) % 7]; // equivalent to subtracting 1, so Monday (1) replaces Sunday (0) as first day of week
  }
  if (valuetype === DateFormatters.MONTH) {
    const currentMonth = Number(month) - 1;
    return months[currentMonth];
  }
  if (valuetype === DateFormatters.MDY) {
    const currentMonth = months[Number(month) - 1].substring(0, 3);
    return `${currentMonth} ${day}, ${year}`;
  }
  if (valuetype === DateFormatters.YMDHMS) {
    if (renderType === ColumnRenderTypes.DateRender) {
      const stringToDate = moment(value).utc();
      const formattedDate = stringToDate.format(DateConversionFormat); // Format date as 'yyyy-MM-dd'
      const formattedTime = stringToDate.format(TimeConversionFormat); // Format time as 'HH:mm:ss'
      return `${formattedDate} ${formattedTime}`;
    }
    const UserTimeZone = getUserTimeZone(userTimeZone || DefaultTimezone);
    const formattedDate = moment.tz(value, UserTimeZone).format('YYYY-MM-DD HH:mm:ss');
    return `${formattedDate}`;
  }
  const newStr = valuetype.replace(/\//g, seperator);
  const newFormat = format(new Date(Number(year), Number(month) - 1, Number(day)), newStr);
  return newFormat;
};

// seperator utility

const seperatorFn = (newValue:string, seperateValue:any) => {
  if (seperateValue === 1000) {
    return newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  if (seperateValue === 100) {
    return newValue.toString().replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',');
  }
  return newValue;
};

// Duration Formatter

export const durationFormatter = (value:number, valueType:DurationFormatter, isCharts: boolean) => {
  if (isCharts) {
    return value;
  }
  let hours; let mins;
  let seconds = value;

  if (valueType === DurationFormatter.MMSS) {
    mins = Math.floor(seconds / 60);
    seconds %= 60;
    return `${mins}m:${Math.floor(seconds)}s`;
  }
  if (valueType === DurationFormatter.MMSSNoSuffix) {
    mins = Math.floor(seconds / 60);
    seconds %= 60;
    return `${mins}:${Math.floor(seconds)}`;
  }
  if (valueType === DurationFormatter.HHMM) {
    mins = Math.floor(seconds / 60);
    hours = Math.floor(mins / 60);
    mins %= 60;
    return `${hours}h:${Math.floor(mins)}m`;
  }
  if (valueType === DurationFormatter.HHMMNoSuffix) {
    mins = Math.floor(seconds / 60);
    hours = Math.floor(mins / 60);
    mins %= 60;
    return `${hours}:${Math.floor(mins)}`;
  }

  if (valueType === DurationFormatter.DHH) {
    mins = Math.floor(seconds / 60);
    hours = Math.floor(mins / 60);
    mins %= 60;
    const days = Math.floor(hours / 24);
    hours %= 24;
    return `${days}D:${Math.floor(hours)}h`;
  }

  if (valueType === DurationFormatter.DAYS) {
    const Days = Math.floor(seconds / 86400);
    return `${Days}Days`;
  }

  if (valueType === DurationFormatter.HMMSS) {
    mins = Math.floor(seconds / 60);
    seconds %= 60;
    hours = Math.floor(mins / 60);
    mins %= 60;
    return `${hours}h:${mins}m:${seconds}s`;
  }

  if (valueType === DurationFormatter.HMMSSNoSuffix) {
    mins = Math.floor(seconds / 60);
    seconds %= 60;
    hours = Math.floor(mins / 60);
    mins %= 60;
    return `${hours}:${mins}:${seconds}`;
  }

  return `${value}s`;
};

export const getFormattedValues = (
  value: any,
  formatter: ReportResponseModel.IColumnFormatter,
  userTimeZone?: string,
  isCharts?: boolean,
  renderType?: ColumnRenderTypes,
) => {
  if (value === null || !formatter?.FormatterType) {
    return value;
  }

  const { FormatterType, FormatterMeta } = formatter;

  switch (FormatterType) {
    case Formatters.BOOLEAN:
      return booleanFormatter(value, FormatterMeta.BooleanStyle, FormatterMeta.CustomValues);

    case Formatters.NUMBER:
    case Formatters.INT_NUMBER:
    case Formatters.DURATION:
    case Formatters.CURRENCY: {
      const { numberValue, hasConversionError } = convertNonNumberToNumber(value);
      if (hasConversionError) return value; // cast failed, returning default value. No formatting will be applied
      if (FormatterType === Formatters.NUMBER) return numberFormatter(numberValue, FormatterMeta.Decimal, FormatterMeta.Seperator);
      if (FormatterType === Formatters.INT_NUMBER) return intNumberFormatter(numberValue, FormatterMeta.Decimal);
      if (FormatterType === Formatters.DURATION) return durationFormatter(value, FormatterMeta.DurationStyle, isCharts);
      return currencyFormatter(
        numberValue,
        FormatterMeta.Decimal,
        FormatterMeta.Symbol,
        FormatterMeta.Seperator,
        isCharts,
      );
    }

    case Formatters.DATE:
      return dateFormatter(value, FormatterMeta.DateStyle, FormatterMeta.Seperator, userTimeZone, renderType);

    case Formatters.STRING:
      return stringFormatter(value, FormatterMeta.StringType);

    case Formatters.TIME:
      return timeFormatter(value, FormatterMeta.TimeStyle, FormatterMeta.Seperator);

    default:
      return value;
  }
};

// convertNonNumberToNumber converts non number values to number
// param: value to be converted
// returns: numberValue, converted value or 0 in case of failed
// returns: hasConversionError, when casting failed
const convertNonNumberToNumber = (value:any): {numberValue: number, hasConversionError: boolean} => {
  const numberValue = Number(value);
  // checking if value is string or NaN
  if (Number.isNaN(numberValue)) {
    // cast failed
    return {
      numberValue: 0,
      hasConversionError: true,
    };
  }
  // number is proper
  return {
    numberValue,
    hasConversionError: false,
  };
};

export const getFormattedValuesForBinning = (value: any, type: string) => {
  if (value) {
    // Getting the date from the value and splitting it on the basis of '-'.
    // Using substring because sometimes we may recieve the value with time. Eg 01-10-2022 02:17:44
    const [year, month, day] = value.toString().substring(0, 10).split('-');
    const currentMonth: string = months[Number(month) - 1];
    switch (type) {
      case DateBinningFormatters.NONE:
      case DateBinningFormatters.HOUROFTHEDAY:
      case DateBinningFormatters.QUARTER:
      case DateBinningFormatters.QUARTEROFTHEYEAR:
      case DateBinningFormatters.YEAR:
      {
        return value;
      }

      case DateBinningFormatters.DATE:
      case DateBinningFormatters.DATE_YEAR:
      {
        return `${currentMonth && currentMonth.substring(0, 3)} ${day}, ${year}`;
      }

      case DateBinningFormatters.MONTHOFTHEYEAR: {
        return `${currentMonth && currentMonth.substring(0, 3)}`;
      }

      case DateBinningFormatters.MONTH: {
        return `${currentMonth && currentMonth.substring(0, 3)} ${year}`;
      }

      case DateBinningFormatters.DAY: {
        return daysOfWeek[value - 1];
      }

      case DateBinningFormatters.WEEK: {
        const weekYear = value.toString().substring(0, 4);
        const week = value.toString().substring(4);
        return `${weekYear} W${week}`;
      }

      case DateBinningFormatters.WEEKOFTHEYEAR: {
        const week = value < 10 ? `0${value}` : value;
        return `Week ${week}`;
      }

      default: {
        return value;
      }
    }
  }
  return value;
};
/*
  method to extracts all the {param} and returns as an array of strings
  Ex: link  : OpportunityManagement/OpportunityDetails?opportunityId={opportunityId}&opportunityEvent={Event}
      output: ['opportunityId', 'Event']
*/
export const getCurlyBracesParamsFromString = (link:string) => {
  const params:string[] = [];
  let foundOpenCurlyBraces = false;
  let temp = '';
  for (let i = 0; i < link.length; i += 1) {
    const c = link[i];
    if (c === '}') {
      params.push(temp);
      temp = '';
      foundOpenCurlyBraces = false;
    } else if (foundOpenCurlyBraces) {
      temp += c;
    } else if (c === '{') {
      foundOpenCurlyBraces = true;
    }
  }
  return params;
};

export const getDuration = (d1:any, d2:any, separator:string):string => {
  if (d1 >= d2) {
    return '';
  }
  let diff = (d2 - d1) / 1000;
  diff = Math.abs(Math.floor(diff)); // difference in seconds

  let leftSec;
  const days = Math.floor(diff / (24 * 60 * 60));
  leftSec = diff - days * 24 * 60 * 60;

  const hrs = Math.floor(leftSec / (60 * 60));
  leftSec -= hrs * 60 * 60;

  const min = Math.floor(leftSec / (60));
  leftSec -= min * 60;
  let remTime:string = '';
  if (days !== 0) remTime += `${days}d${separator}`;
  if (hrs !== 0) remTime += `${hrs}hr${separator}`;
  if (min !== 0) remTime += `${min}m`;
  return remTime;
};

export const formattedCurrentDate = () => {
  const today = new Date().toString().substring(4, 21); // mon dd yyyy hh:mm
  const formattedToday = today.replace(/ |:/g, (el) => (el === ':' ? '' : '_')); // mon_dd_yyyy_hhmm
  return formattedToday;
};

export const getUserTimeZone = (userTimeZone:string) => (userTimeZone === InternationalDateLineWest ? DefaultTimezone : userTimeZone);

/*
All the available time options for future purpose
const options:Intl.DateTimeFormatOptions = {
  timeZone: 'NZ',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: boolean
};
*/

// Ex: 15/11/2022, 04:58:12 --> 15 November 2022
export const getTodaysDateAccordingToUserTimeZone = (userTimeZone:string = DefaultTimezone) => {
  const options:Intl.DateTimeFormatOptions = {
    day: 'numeric',
    timeZone: getUserTimeZone(userTimeZone),
    hour12: false,
  };
  const formatter = new Intl.DateTimeFormat([], options);
  const now = new Date();
  now.setDate(Number(formatter.format(new Date())));
  return now.toLocaleDateString('en-GB', {
    day: 'numeric', month: 'long', year: 'numeric',
  }).replace(/ /g, ' '); // dd month yyyy
};

// Ex: 15/11/2022, 04:58:12 --> 14 November 2022
export const getYesterdaysDateAccordingToUserTimeZone = (userTimeZone:string = DefaultTimezone) => {
  const options:Intl.DateTimeFormatOptions = {
    day: 'numeric',
    timeZone: getUserTimeZone(userTimeZone),
    hour12: false,
  };
  const formatter = new Intl.DateTimeFormat([], options);
  const now = new Date();
  now.setDate(Number(formatter.format(new Date())) - 1);
  return now.toLocaleDateString('en-GB', {
    day: 'numeric', month: 'long', year: 'numeric',
  }).replace(/ /g, ' '); // dd month yyyy
};

// Ex: 15/11/2022, 04:58:12 -->15
export const getTodaysDayAccordingToUserTimeZone = (userTimeZone:string = DefaultTimezone) => {
  const options:Intl.DateTimeFormatOptions = {
    day: 'numeric',
    timeZone: getUserTimeZone(userTimeZone),
    hour12: false,
  };
  const formatter = new Intl.DateTimeFormat([], options);
  return Number(formatter.format(new Date()));
};

// Ex: 15/11/2022, 04:58:12 --> 4
export const getCurrentHourAccordingToUserTimeZone = (userTimeZone:string = DefaultTimezone) => {
  const options:Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    timeZone: getUserTimeZone(userTimeZone),
    hour12: false,
  };
  const formatter = new Intl.DateTimeFormat([], options);
  return Number(formatter.format(new Date()));
};

// Ex: 15/11/2022, 04:58:12 --> 58
export const getCurrentHoursMinsAccordingToUserTimeZone = (userTimeZone:string = DefaultTimezone) => {
  const options:Intl.DateTimeFormatOptions = {
    minute: 'numeric',
    timeZone: getUserTimeZone(userTimeZone),
    hour12: false,
  };
  const formatter = new Intl.DateTimeFormat([], options);
  return Number(formatter.format(new Date()));
};

export const Minutesformatter = (value: number) => {
  const hours = Math.floor(value / 60);
  const mins = Math.floor(value % 60);

  if (hours === 0) {
    return `${mins} ${mins === 1 ? 'min' : 'mins'}`;
  }
  if (mins === 0) {
    return `${hours} ${hours === 1 ? 'hr' : 'hrs'}`;
  }
  return `${hours}${hours === 1 ? 'hr' : 'hrs'} ${mins}${mins === 1 ? 'min' : 'mins'}`;
};
