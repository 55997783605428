import React from 'react';
import Select, { components } from 'react-select';
import { FilterModel } from 'core/models';
import { IProps, MenuPlacement, MenuPosition } from 'components/common/Dropdown/dropdown.d';
import { ArrowDown } from 'components/common/svg';
import TickIcon from 'components/common/svg/Tick/tick.svg';
import Tooltip from 'rc-tooltip';
import useLocalize from 'components/common/Localization/useLocalize.hook';
import {
  formatGroupLabelContainer, getGroupedDropdownItems,
} from 'core/utils/dropdown.util';
import { mouseEnterDelay } from 'components/feature/Report/ReportSidebar/common/constants';
import { TooltipPlacement } from 'core/constants/report';
import { useSelector } from 'react-redux';
import authSelector from 'redux-v2/auth-store/auth-store.selector';
import { SourceTypes } from 'core/models/auth-response';
import StyleComponent from './dropdown-styles';

const ValueContainerComponent = (props: any) => {
  const { children } = props;
  return (
    <components.ValueContainer {...props}>{children}</components.ValueContainer>
  );
};

const PlaceholderComponent = (props: any) => {
  const { children, selectProps } = props;
  return (
    <components.Placeholder {...props}>
      {selectProps.menuIsOpen ? null : children}
    </components.Placeholder>
  );
};

const OptionComponent = (props: any) => {
  const { children, isSelected, data } = props;
  const pendoClass = data?.pendoClass;

  return (
    <components.Option {...props}>
      <Tooltip
        overlay={children || ''}
        placement={TooltipPlacement.Bottom}
        mouseEnterDelay={mouseEnterDelay}
      >
        <span className={`se-react-select-option-label ${pendoClass}`}>
          {children || '(empty)'}
        </span>
      </Tooltip>
      {isSelected && (
        <span className="se-react-select-tick-icon">
          <TickIcon width={11} height={11} fill="var(--border)" />
        </span>
      )}
    </components.Option>
  );
};

const DropdownIndicatorComponent = (props: any) => {
  const { selectProps } = props;
  return (
    <components.DropdownIndicator {...props}>
      {selectProps?.dropdownIndicator && (
        <>
          {selectProps?.menuIsOpen ? (
            <span className="se-react-select-dropdown-indicator-rotate">
              <ArrowDown width={20} height={20} fill="var(--secondaryColor)" />
            </span>
          ) : (
            <ArrowDown width={20} height={20} fill="var(--secondaryColor)" />
          )}
        </>
      )}
    </components.DropdownIndicator>
  );
};

const SingleSelect = (props: IProps) => {
  const {
    config,
    value,
    onChange,
    removeBorderClass,
    addBorderClass,
    pendoClassName,
    isGroupedOptions,
  } = props;
  const { Key, Meta = {}, Loading = false } = config;
  const {
    Options,
    Placeholder = `${useLocalize('cmn.select')}`,
    DropdownIndicator = true,
    CloseOnSelect = false,
    Searchable = true,
    ClassName = '',
    AppliedOnClose = true,
    Components = {},
  } = Meta as FilterModel.ISelectMeta;

  const ref = React.useRef(null);
  const {
    mobileAuthInfo,
  } = useSelector(authSelector);

  const isAndroidApp = mobileAuthInfo?.source === SourceTypes.ANDROID;
  // Custom Method to have search
  const filterOption = (option: FilterModel.ISelectFilterOptions, searchValue: string) => (searchValue
    ? option.label
      .toString()
      .toLowerCase()
      .includes(searchValue.toLowerCase())
    : true);

  const onReactSelectChange = (option: any) => {
    if (AppliedOnClose) {
      return;
    }
    onChange(Key, option.value);
  };

  const getSelectedValue = () => {
    if (isGroupedOptions) {
      return getGroupedDropdownItems(Options).filter((option) => option.value === value);
    }
    return Options?.filter((option) => option.value === value);
  };

  return (
    <StyleComponent ref={ref}>
      <Select
        isDisabled={config.Disabled}
        options={Options}
        value={getSelectedValue()}
        isClearable={false}
        isSearchable={Searchable}
        placeholder={Placeholder}
        dropdownIndicator={DropdownIndicator}
        closeMenuOnSelect={CloseOnSelect}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        isLoading={Loading}
        className={`se-react-select-cn '' ${ClassName} ${removeBorderClass} ${addBorderClass} ${pendoClassName}`}
        classNamePrefix="se-react-select"
        components={{
          ValueContainer: Components.ValueContainer || ValueContainerComponent,
          Placeholder: Components.Placeholder || PlaceholderComponent,
          Option: Components.Option || OptionComponent,
          DropdownIndicator:
            Components.DropdownIndicator || DropdownIndicatorComponent,
        }}
        formatGroupLabel={formatGroupLabelContainer}
        filterOption={filterOption}
        menuPlacement={MenuPlacement.auto}
        onChange={onReactSelectChange}
        config={config}
        menuPosition={isAndroidApp ? MenuPosition.absolute : MenuPosition.fixed}
      />
    </StyleComponent>
  );
};

export default SingleSelect;
