import React from 'react';

type Props = {
  width: number;
  height: number;
  fill?: string;
}

const TicketActivityEntity = ({
  width, height, fill,
}: Props) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22 10V6C22 4.89 21.1 4 20 4H4C2.9 4 2.01 4.89 2.01 6V10C3.11 10 4 10.9 4 12C4 13.1 3.11 14 2 14V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V14C20.9 14 20 13.1 20 12C20 10.9 20.9 10 22 10ZM20 8.54C18.81 9.23 18 10.53 18 12C18 13.47 18.81 14.77 20 15.46V18H4V15.46C5.19 14.77 6 13.47 6 12C6 10.52 5.2 9.23 4.01 8.54L4 6H20V8.54ZM11 15H13V17H11V15ZM11 11H13V13H11V11ZM11 7H13V9H11V7Z" fill={fill} />
    <circle cx="18" cy="15" r="6" fill={fill} />
    <path d="M17.25 19.5C17.1703 19.4998 17.092 19.4762 17.0232 19.4315C16.9544 19.3868 16.8976 19.3226 16.8585 19.2455L15.2505 16.0598H13.5V15.0617H15.5115C15.592 15.0609 15.6712 15.0842 15.7409 15.1289C15.8105 15.1737 15.8681 15.2384 15.9075 15.3162L17.1 17.6967L18.8145 10.8762C18.8375 10.7759 18.8879 10.6861 18.959 10.619C19.03 10.552 19.118 10.5111 19.2109 10.502C19.3038 10.4928 19.397 10.5159 19.4775 10.5681C19.558 10.6202 19.622 10.6988 19.6605 10.793L20.79 13.4447H22.5V14.4428H20.502C20.4159 14.4431 20.3316 14.4159 20.259 14.3646C20.1864 14.3133 20.1286 14.24 20.0925 14.1534L19.35 12.4366L17.685 19.134C17.6606 19.2292 17.6112 19.3142 17.543 19.3782C17.4749 19.4422 17.3912 19.4823 17.3025 19.4933L17.25 19.5Z" fill="white" />
  </svg>

);

TicketActivityEntity.defaultProps = {
  fill: 'var(--ticketActivityEntity)',
};

export default TicketActivityEntity;
