import { FilterModel } from 'core/models';

export interface IProps {
  config: FilterModel.IConfig;
  value: any;
  isDisabled?:boolean;
  onChange: (
    key: string,
    value: any,
  ) => void;
  removeBorderClass?: string;
  addBorderClass?: string;
  pendoClassName?: string;
  enableV1Reports?:boolean;
  isReportBuilder?: boolean;
  isGroupedOptions?: boolean;
}

export enum MenuPosition {
  fixed = 'fixed',
  absolute = 'absolute'
}

export enum MenuPlacement {
  auto = 'auto'
}
