import { IDimension } from 'core/models/report-redux';
import { VisualizationTypes } from 'core/constants/visualizations';
import {
  MaxDrilldownGrouping, MinRowGrouping, MAX_NAME_CHARACTER_LIMIT,
} from 'core/constants/report';
import {
  IColumnWithIndex,
} from 'components/feature/Report/ReportSidebar/Settings/DrilldownDimensions/DrilldownGrouping/drilldown-grouping.d';
import { IColumn } from 'core/models/report-response';
import isEmpty from 'lodash/isEmpty';

function checkAndGetGroupingLimits(rowGroupedDimensions: IDimension[], colGroupedDimensions: IDimension[], type: VisualizationTypes, rowGroupingLimit?: number, colGroupingLimit?: number) {
  let minRowGroupingLimit = 1;
  let maxRowGroupingLimit = 1;
  let maxColGroupingLimit = 1;

  const rowGroupingCount = rowGroupedDimensions ? rowGroupedDimensions.length : 0;
  const columnGroupingCount = colGroupedDimensions ? colGroupedDimensions.length : 0;
  switch (type) {
    case VisualizationTypes.Table:
    case VisualizationTypes.SummaryTable:
      minRowGroupingLimit = MinRowGrouping.Table;
      maxRowGroupingLimit = rowGroupingLimit;
      maxColGroupingLimit = colGroupingLimit;
      break;

    case VisualizationTypes.AreaChart:
    case VisualizationTypes.BarChart:
    case VisualizationTypes.ColumnChart:
    case VisualizationTypes.LineChart:
      minRowGroupingLimit = MinRowGrouping.ColumnChart;
      maxRowGroupingLimit = rowGroupingLimit;
      maxColGroupingLimit = colGroupingLimit;
      break;

    case VisualizationTypes.PieChart:
    case VisualizationTypes.DonutChart:
      minRowGroupingLimit = MinRowGrouping.PieChart;
      maxRowGroupingLimit = rowGroupingLimit;
      maxColGroupingLimit = colGroupingLimit;
      break;

    default:
      break;
  }

  const isMinRowGroupingReached = rowGroupingCount <= minRowGroupingLimit;
  const isMaxRowGroupingReached = rowGroupingCount >= maxRowGroupingLimit;
  const isMaxColGroupingReached = columnGroupingCount >= maxColGroupingLimit;

  return {
    isMinRowGroupingReached,
    isMaxRowGroupingReached,
    minRowGroupingLimit,
    maxRowGroupingLimit,
    isMaxColGroupingReached,
    maxColGroupingLimit,
  };
}

function checkDrilldownLimits(groupedDimensions : IColumnWithIndex[]) {
  const minDrilldownGroupingLimit = 1;
  const drilldownGroupingCount = groupedDimensions ? groupedDimensions.length : 0;
  const isMinDrilldownGroupingReached = drilldownGroupingCount <= minDrilldownGroupingLimit;
  const isMaxDrilldownGroupingReached = drilldownGroupingCount >= MaxDrilldownGrouping;
  return {
    isMaxDrilldownGroupingReached,
    MaxDrilldownGrouping,
    isMinDrilldownGroupingReached,
    minDrilldownGroupingLimit,
  };
}

function displayName(
  dimension: IDimension | IColumn, withEnity: boolean = false, columns?: IColumn[], groupedDimensions?: IDimension[],
): string { // TODO: Make this a method on IDimension
  if (!dimension) {
    return '';
  }
  let matchingDimension;

  if (dimension.BuilderConfig && dimension.BuilderConfig.IsDynamicField) {
    if (groupedDimensions) {
      matchingDimension = isValidArrayAndNotEmpty(groupedDimensions) && groupedDimensions.find((dim) => dim.Id === (dimension as IDimension).ReferTo);
    }
    return `${withEnity ? `${dimension.BuilderConfig?.Entity} | ` : ''}${matchingDimension?.Name || dimension.BuilderConfig.DisplayName}`;
  }
  if (columns) {
    matchingDimension = isValidArrayAndNotEmpty(columns) && columns.find((column) => column.Id === (dimension as IDimension).ReferTo);
  }
  return matchingDimension?.Name || dimension.Name || '';
}

function isValidArray(input: any) {
  return Array.isArray(input);
}
function isValidArrayAndNotEmpty(input:any) {
  return Array.isArray(input) && input.length > 0;
}
function isValidObjectAndNotEmpty(input: any) {
  return typeof input === 'object' && input !== null && !isEmpty(input);
}
function isValidUUID(uuid: string): boolean {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
}
const trimClonedDashboardTitle = (label : string) => {
  if (label.length >= MAX_NAME_CHARACTER_LIMIT) {
    return label.substring(0, MAX_NAME_CHARACTER_LIMIT);
  }
  return label;
};

export {
  checkAndGetGroupingLimits, displayName, checkDrilldownLimits, isValidArray, isValidObjectAndNotEmpty, isValidArrayAndNotEmpty, trimClonedDashboardTitle, isValidUUID,
};
